import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {PrivateRoute} from "views/components/route";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import styled from "@emotion/styled";

const GamePlayPage = lazy(retryFailLoad(() => import("views/pages/gameplay/gameplay.page")));
const ResetPasswordPage = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

const Main = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: 100dvh;
`;

export const RootRoutes: React.FC = () => (
	<Main>
		<Header />
		<Routes>
			<Route element={<PrivateRoute />}>
				<Route path="/my-account" element={<MyAccount />} />
			</Route>

			<Route index element={<GamePlayPage />} />
			<Route path="/reset-password" element={<ResetPasswordPage />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
		<Footer />
	</Main>
);

export default RootRoutes;
