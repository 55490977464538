import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const ModalContent = styled.div`
	position: fixed;
	left: 20px;
	right: 20px;
	top: 80px;
	max-width: calc(420px - 40px);
	margin: 0 auto;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background: #ffffff;
	outline: none;
	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 68px 20px 20px 20px;
	gap: 12px;
	max-height: calc(100% - 100px);
	overflow: auto;

	&.full-screen {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		max-height: 100%;
		max-width: 420px;
		border-radius: 0;

		img.dark {
			background: #222222;
		}
	}

	&.no-padding {
		padding: 0;
	}

	&.terms {
		z-index: 1100;
	}

	@media screen and (max-width: 640px) {
		top: 20px;
		max-height: calc(100% - 40px);
	}
`;

export const ModalCloseButton = styled(Button)`
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 24px;
	height: 24px;
	padding: 0;

	&.highlight {
		min-width: unset;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background: #333333;
	}
`;

export const ModalTitle = styled.h3`
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	margin: 10px 0 0 0;
`;

export const ModalText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
`;

export const ModalButton = styled(Button)`
	max-width: 420px;
	width: 100%;
	margin-top: 12px;
`;

export const ModalIcon = styled.div`
	width: 52px;
	height: 52px;

	&.error {
		svg {
			width: 100%;
			height: auto;

			path {
				color: ${({theme}) => theme.color.brand.error};
				fill: ${({theme}) => theme.color.brand.error};
			}
		}
	}

	&.success {
		svg {
			width: 100%;
			height: auto;

			path {
				color: ${({theme}) => theme.color.brand.success};
				fill: ${({theme}) => theme.color.brand.success};
			}
		}
	}

	&.restore {
		width: 48px;
		height: 48px;
		background: ${({theme}) => theme.color.brand.info};
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;

		svg {
			margin-right: 2px;
			width: 100%;
			height: auto;

			path {
				color: #ffffff;
			}
		}
	}
`;

export const ModalHeadingImage = styled.img`
	width: 100%;
	margin-top: -2px;
`;
