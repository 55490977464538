import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {Empty} from "data/types/generics";
import type {IChecksums} from "data/types/entities";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import type {IUserStore} from "data/stores/user/user.store";
import {LIVE_SCORING_UPDATE_TIMING} from "data/constants";
import type {IStaticContentStore} from "data/stores/static_content/static_content.store";

export interface ILiveScoringStore {
	subscribeLiveScoring: () => void;
	unsubscribeLiveScoring: () => void;
}

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

@injectable()
export class LiveScoringStore implements ILiveScoringStore {
	@observable protected _interval: Empty<NodeJS.Timeout>;
	@observable protected _location: Empty<string>;

	@observable protected _isSubscribed: boolean = false;
	protected readonly _actions: IChecksumAction;
	@observable protected _checksums: IChecksums = {
		contests: null,
		settings: null,
	};

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.StaticContentStore) private _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this.fetchChecksums();
		this._interval = setInterval(() => {
			void this.fetchChecksums();
		}, LIVE_SCORING_UPDATE_TIMING);
	}

	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	@action
	protected async fetchChecksums(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.checksums();
			this.compareChecksums(data);
		} catch (e) {
			console.error("Error while fetching checksums");
		}
	}

	@action
	protected compareChecksums(checksums: IChecksums): void {
		Object.keys(checksums).forEach((e) => {
			const key = e as keyof IChecksums;
			const oldChecksum = this._checksums[key];

			if (oldChecksum !== checksums[key] && oldChecksum !== null) {
				const action = this._actions[key];
				if (action && typeof action === "function") {
					void action();
				}
			}
		});
		this._checksums = checksums;
	}

	private generateActions(): IChecksumAction {
		return {
			contests: () => this.fetchContestsAction(),
			settings: () => this.fetchSettings(),
		};
	}

	private fetchContestsAction(): Promise<void> {
		if (this._userStore.isAuthorized) {
			void this._gameBarStore.fetchGameBar();
		}
		return this._gameplayStore.fetchContests();
	}

	private fetchSettings(): Promise<void> {
		return this._staticContentStore.fetchSettings();
	}
}
