import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/api";

export interface ICountry {
	name: string;
	code: string;
}

export interface ICountriesStore {
	list: ICountry[];

	fetchCountries(): Promise<void>;
}

@injectable()
export class CountriesStore implements ICountriesStore {
	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _list: ICountry[] = [
		{
			code: "USA",
			name: "United States",
		},
	];

	get list() {
		return this._list;
	}

	@action
	async fetchCountries() {
		try {
			const {data} = await this._jsonProvider.countries();
			if (!Array.isArray(data)) {
				throw new Error("Invalid countries response");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
		}
	}
}
