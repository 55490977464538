import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import React from "react";
import type {Empty} from "data/types/generics";
import {ICreateBingoBoardPayload} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ISquadsStore, SquadLogos} from "data/stores/squads/squads.store";

type onSelectFunction = (params: ICreateBingoBoardPayload) => void;

interface IParams {
	onSelect: onSelectFunction;
	contestId: number;
	selectedSquad: Empty<number>;
}

export interface ICreateBingoBoardButtonsController extends ViewController<IParams> {
	handleSelect: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	getIsSquadNonChosen: (squadId: Empty<number>) => string;

	getSquadImageType: (squadId: Empty<number>) => SquadLogos;

	passSelectedSquad: (squadId: Empty<number>) => void;
}

@injectable()
export class CreateBingoBoardButtonsController implements ICreateBingoBoardButtonsController {
	@observable protected _selectedSquad: Empty<number>;
	@observable protected _onSelect: Empty<onSelectFunction>;
	@observable protected _contestId: Empty<number>;

	constructor(@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._onSelect = param.onSelect;
		this._contestId = param.contestId;
		this._selectedSquad = param.selectedSquad;
	}

	@action
	public getIsSquadNonChosen = (squadId: Empty<number>) => {
		if (!squadId) {
			return "";
		}
		if (!this._selectedSquad) {
			return "non-chosen";
		}
		return this._selectedSquad !== squadId ? "non-chosen" : "";
	};

	public handleSelect = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
		const squadId = Number(event.currentTarget.dataset.squad);
		if (!squadId || !this._contestId) {
			return;
		}

		this._selectedSquad = squadId;
		this._onSelect?.({squadId, contestId: this._contestId});
	};

	public getSquadImageType = (squadId: Empty<number>): SquadLogos => {
		const squad = this._squadStore.getSquadById(squadId);
		if (!squad || !this._selectedSquad) {
			return "blackWhiteLogo";
		}
		return squadId === this._selectedSquad ? "logo" : "blackWhiteLogo";
	};

	@action
	public passSelectedSquad = (squadId: Empty<number>) => {
		if (squadId) {
			this._selectedSquad = squadId;
		}
	};
}
