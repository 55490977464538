import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {IContest, IGameCard} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {ContestStatus} from "data/enums";

export interface IEntriesLabelController extends ViewController {
	get isVisible(): boolean;

	get entriesWon(): number;

	get isClaimed(): boolean;
}

@injectable()
export class EntriesLabelController implements IEntriesLabelController {
	constructor(
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore
	) {
		makeAutoObservable(this);
	}

	get isVisible(): boolean {
		if (!this.selectedContest || !this.gameCard) {
			return false;
		}
		if (this.isLive && this.entriesWon === 0) {
			return false;
		}
		return this.selectedContest.status !== ContestStatus.Open;
	}

	get entriesWon(): number {
		const gameBar = this._gameBarStore.getGameBarEntityByContestId(this.selectedContest?.id);
		if (!gameBar || !gameBar?.entriesWon) {
			return 0;
		}
		return gameBar.entriesWon;
	}

	get isClaimed(): boolean {
		const gameBar = this._gameBarStore.getGameBarEntityByContestId(this.selectedContest?.id);
		if (!gameBar) {
			return false;
		}
		return Boolean(gameBar.displayedModal);
	}

	protected get gameCard(): Empty<IGameCard> {
		return this._gameplayStore.gameCard;
	}

	protected get selectedContest(): Empty<IContest> {
		return this._gameplayStore.selectedContest;
	}

	protected get isLive(): boolean {
		if (!this.selectedContest) {
			return false;
		}
		return this.selectedContest.status === ContestStatus.Live;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
