import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IHOCMainController} from "views/components/HOC/HOCMain/HOCMain.controller";
import {Bindings} from "data/constants/bindings";
import {Preloader} from "views/components/preloader";
import {Exist} from "views/components/exist/exist.component";
import {ModalLogin} from "views/components/modals/modalLogin/modalLogin.component";
import {ModalRegister} from "views/components/modals/modalRegister/modalRegister.component";
import {ModalForgotPassword} from "views/components/modals/modalForgotPassword/modalForgotPassword.component";
import {ModalTerms} from "views/components/modals/modalTerms/modalTerms.component";
import {ModalPreRegistration} from "views/components/modals/modalPreRegistration/modalPreRegistration.component";

interface IProps {
	children: React.ReactNode;
}

export const HOCMain: React.FC<IProps> = observer(({children}) => {
	const {isLoading, hasSettings} = useViewController<IHOCMainController>(
		Bindings.HOCMainController
	);

	if (!hasSettings) {
		return <Preloader fixed />;
	}

	return (
		<React.Fragment>
			<Exist when={isLoading}>
				<Preloader fixed />
			</Exist>

			<Exist when={!isLoading}>{children}</Exist>

			<ModalLogin />
			<ModalRegister />
			<ModalPreRegistration />
			<ModalForgotPassword />
			<ModalTerms />
		</React.Fragment>
	);
});
