import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";
import {IFormForgotPassword} from "data/types/entities";
import type {IUserStore} from "data/stores/user/user.store";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {Empty} from "data/types/generics";
import {ModalType} from "data/enums";

export interface IModalForgotPasswordController extends ViewController {
	close: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IFormForgotPassword>) => void;
	handleFormChange: (event: React.ChangeEvent<IFormForgotPassword>) => void;

	get isLoading(): boolean;

	get isSubmitDisabled(): boolean;

	get error(): Empty<string>;

	get isOpen(): boolean;
}

@injectable()
export class ModalForgotPasswordController implements IModalForgotPasswordController {
	@observable protected _isEmailFilled = false;
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.FORGOT_PASSWORD);
	}

	@observable private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isSubmitDisabled(): boolean {
		return !this._isEmailFilled || this.isLoading;
	}

	public handleFormSubmit = (event: React.SyntheticEvent<IFormForgotPassword>) => {
		event.preventDefault();

		const value = event.currentTarget.email?.value;
		if (!value) {
			return;
		}

		this._error = undefined;
		this.resetPassword(value);
	};

	public handleFormChange = (event: React.ChangeEvent<IFormForgotPassword>) => {
		const email = event.currentTarget.email.value;
		this._isEmailFilled = Boolean(email.trim());
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal(ModalType.FORGOT_PASSWORD);
	};

	@action
	protected resetPassword(email: string): void {
		this._isLoading = true;
		this._userStore
			.forgotPassword(email)
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	}

	@action
	protected onSuccess() {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.SUCCESS, {
			title: "Email Sent",
			message:
				"A link has been sent to your email address to reset your password. If it was not received please contact us or try with a different email address.",
		});
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while making request, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}
}
