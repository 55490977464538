import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IHOCSecretKeyController} from "views/components/HOC/HOCSecretKey/HOCSecretKey.controller";
import {Bindings} from "data/constants/bindings";
import {Modal} from "@mui/material";
import {AuthContainer} from "views/components/common/layouts";
import Logo from "assets/img/header-logo.png";
import {ModalTitle} from "views/components/common/modal";

interface IProps {
	children: React.ReactNode;
}

export const HOCSecretKeyComponent: React.FC<IProps> = observer(({children}) => {
	const {isVisible, handleSecretClick} = useViewController<IHOCSecretKeyController>(
		Bindings.HOCSecretKeyController
	);
	if (!isVisible) {
		return <React.Fragment>{children}</React.Fragment>;
	}
	return (
		<Modal open={true}>
			<AuthContainer>
				{/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
				<img onClick={handleSecretClick} src={Logo} alt="logo" />
				<ModalTitle>Coming soon</ModalTitle>
			</AuthContainer>
		</Modal>
	);
});
