import {ThemeOptions} from "@mui/material/styles/createTheme";
import {colorsTheme} from "assets/theming/palette.theme";

const focusedClass = ".Mui-focused";

export const inputsTheme: ThemeOptions = {
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					borderColor: colorsTheme.color.components.input.defaultColor,
					input: {
						fontWeight: "500",
					},
					[`${focusedClass} fieldset.MuiOutlinedInput-notchedOutline`]: {
						borderColor: colorsTheme.color.components.input.focusedColor,
					},
					[`label${focusedClass}`]: {
						color: colorsTheme.color.components.input.focusedColor,
					},
					".MuiFormHelperText-root": {
						color: colorsTheme.color.components.input.helperColor,
						borderColor: colorsTheme.color.components.input.defaultColor,
						marginLeft: 0,
						textTransform: "inherit",
					},
					".Mui-error input": {
						borderColor: colorsTheme.color.components.input.error,
					},
					".Mui-disabled": {
						opacity: "0.5",
					},
				},
			},
		},
	},
};
