import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {IContest} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import {ContestStatus} from "data/enums";
import {DateTime} from "luxon";

interface IParams {
	contestId: Empty<number>;
}

export interface IContestLabelController extends ViewController<IParams> {
	get labelClass(): string;

	get labelText(): string;

	onChange: (contestId: number) => void;
}

@injectable()
export class ContestLabelController implements IContestLabelController {
	protected _contestId: Empty<number>;

	constructor(@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore) {
		makeAutoObservable(this);
	}

	get labelClass(): string {
		return this.contest?.status || "";
	}

	get labelText(): string {
		if (!this.contest) {
			return "-";
		}
		switch (this.contest.status) {
			case ContestStatus.Draft:
				return this.contestDate;
			case ContestStatus.Open:
				return this.contestDate;
			case ContestStatus.Live:
				return "Live";
			case ContestStatus.Complete:
				return "Complete";
			default:
				return "-";
		}
	}

	protected get contest(): Empty<IContest> {
		return this._gameplayStore.getContestById(this._contestId);
	}

	protected get contestDate(): string {
		if (!this.contest) {
			return "-";
		}
		// <month>/<day> @ <time in user's timezone>" - 1/20 @ 7:55PM ET
		const dateTime = DateTime.fromISO(this.contest.date).toFormat("L/d @ h:mma ZZZZ");
		return `Kickoff: ${dateTime}`;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
	}

	@action
	public onChange = (contestId: number) => {
		this._contestId = contestId;
	};
}
