import {AbstractValidator} from "data/utils/validators/abstract.validator";
import {THTMLFormElements} from "data/types/entities";

export class PasswordValidator extends AbstractValidator {
	getErrorMessage(): string {
		return "Min 8 characters, one uppercase letter, one number, and one special character";
	}

	validate(formControl: THTMLFormElements): boolean {
		return new RegExp(
			"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$"
		).test(formControl.value);
	}
}
