import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IRegistrationPayload, IResetPasswordPayload} from "data/types/entities";

export interface ILoginPayload {
	email: string;
	password: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	user: () => Promise<AxiosResponse<TLoginResponse>>;
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	register: (payload: IRegistrationPayload) => Promise<AxiosResponse<TLoginResponse>>;
	forgotPassword: (email: string) => Promise<AxiosResponse>;
	resetPassword: (payload: IResetPasswordPayload) => Promise<AxiosResponse>;
	registerForGame: () => Promise<AxiosResponse>;
	updateUser: (payload: Partial<IUser>) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	user = () => this._http.get<TLoginResponse>("en/user");
	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("en/auth/login", params);
	register = (params: IRegistrationPayload) =>
		this._http.post<TLoginResponse>("en/user/register", params);

	logout = () => this._http.post("en/auth/logout");

	forgotPassword(email: string): Promise<AxiosResponse> {
		return this._http.post("en/password_reset/request", {email});
	}

	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse> {
		return this._http.post("en/password_reset", payload);
	}

	registerForGame(): Promise<AxiosResponse> {
		return this._http.post("en/user/activate_for_game");
	}
	updateUser(payload: Partial<IUser>) {
		return this._http.post<TLoginResponse>("en/user/update", payload);
	}
}
