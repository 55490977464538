export class TutorialHelper {
	protected static readonly storageKey = "xlf_pb_tutorial_viewed";

	public static getIsTutorialViewed(): boolean {
		const storageValue = localStorage.getItem(this.storageKey);
		return JSON.parse(storageValue || "false") as boolean;
	}

	public static setTutorialViewed(): void {
		localStorage.setItem(this.storageKey, "true");
	}
}
