import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {ContestStatus, ModalType} from "data/enums";
import {IClaimBingoContestEntity} from "data/types/entities";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";

export interface IModalClaimBingoController extends ViewController {
	claimBingo: () => void;

	get isOpen(): boolean;

	get isLoading(): boolean;

	get contestsToShow(): IClaimBingoContestEntity[];
}

@injectable()
export class ModalClaimBingoController implements IModalClaimBingoController {
	constructor(
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.CLAIM_BINGO);
	}

	get contestsToShow(): IClaimBingoContestEntity[] {
		return this.claimContests.map((gameBar) => ({
			contest: this._gameplayStore.getContestById(gameBar.contestId),
			entriesWon: gameBar.entriesWon,
			hasEntriesWon: Boolean(gameBar.entriesWon),
		}));
	}

	protected get claimContests() {
		return this._gameBarStore.gameBar.filter(
			(e) => e.status === ContestStatus.Complete && !e.displayedModal
		);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public claimBingo = (): void => {
		const promises = this.contestsToShow.map((e) =>
			this._gameBarStore.setClaimModalViewed(e.contest?.id)
		);

		this._isLoading = true;
		Promise.all(promises)
			.then(this.onSuccess.bind(this))
			.catch(this.onError.bind(this))
			.finally(this.onFinally.bind(this));
	};

	protected onSuccess(): void {
		void this._gameBarStore.fetchGameBar();
		this._modalsStore.hideModal();
	}

	protected onError(error: AxiosError<IAxiosApiError, unknown> | undefined): void {
		this._modalsStore.showAxiosError(error);
	}

	protected onFinally(): void {
		this._isLoading = false;
	}
}
