import {IAxiosApiErrorGeneral, IAxiosErrorMessageObject} from "data/types/api";
import {Nullable, NullOrEmpty} from "data/types/generics";
import {IMAGES_URL} from "data/constants";

export function getClassNameAccordingStatement(className: string, statement: boolean): string {
	return statement ? className : "";
}

export function getEmptyStringValue<T>(value: NullOrEmpty<T>): string {
	return getDashedValue(value, "");
}

export function getDashedValue<T>(value: NullOrEmpty<T>, replacer = "-"): string {
	if (value === null || value === undefined) {
		return replacer;
	}
	return String(value);
}

export function getNonUndefinedValue<T>(value: NullOrEmpty<T>) {
	if (value === undefined || value === null) {
		return 0;
	}
	return value;
}

export function getErrorMessageFromAxiosResponse(event: IAxiosApiErrorGeneral) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function getImageFromBackend(image: Nullable<string>): string {
	if (!image) {
		return "";
	}
	return IMAGES_URL + image;
}

export function serializeForm<T>(data: FormData): T {
	const keys = Array.from(data.keys());
	const response = {};
	keys.forEach((key) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		response[key] = data.get(key);
	});
	return response as T;
}

export function pluralForms(
	counter: NullOrEmpty<number>,
	singleForm: string,
	multipleForm?: string
): string {
	if (!counter) {
		return singleForm;
	}
	const multipleFormFinal = multipleForm ? multipleForm : singleForm + "s";
	return counter > 1 ? multipleFormFinal : singleForm;
}
