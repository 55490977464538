import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {Empty} from "data/types/generics";
import {IFormRegister, IRegistrationPayload, ValidationScheme} from "data/types/entities";
import {PasswordValidator} from "data/utils/validators/PasswordValidator";
import {PostcodeValidator} from "data/utils/validators/Postcode.validator";
import {UsernameValidator} from "data/utils/validators/Username.validator";
import {POSTCODE_COUNTRIES} from "data/constants";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {ModalType} from "data/enums";

export interface IFormRegisterController extends ViewController {
	togglePasswordVisibility: () => void;
	toggleConfirmPasswordVisibility: () => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	handleFormSubmit: (event: React.SyntheticEvent<IFormRegister>) => void;
	handleCountryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	goToLogin: () => void;

	get isLoading(): boolean;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get squads(): ISquad[];

	get countries(): ICountry[];

	get isPostcodeVisible(): boolean;

	get formErrors(): IFormValidator["formErrors"];

	get error(): Empty<string>;
}

@injectable()
export class FormRegisterController implements IFormRegisterController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
		postCode: [new PostcodeValidator()],
		username: [new UsernameValidator()],
	};

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	@observable private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get squads(): ISquad[] {
		return this._squadStore.list;
	}

	get countries() {
		return this._countriesStore.list;
	}

	@observable protected _isPostcodeVisible = true;

	get isPostcodeVisible() {
		return this._isPostcodeVisible;
	}

	get formErrors() {
		return this._formValidator.formErrors;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._countriesStore.fetchCountries();
	}

	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	public toggleConfirmPasswordVisibility = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action
	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		const {name} = event.target;

		if (!name) {
			return;
		}
		this._formValidator.clearError(name);
		this._error = undefined;
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IFormRegister>) => {
		event.preventDefault();
		this.clearErrors();
		this._formValidator.validate(event.currentTarget);

		const {
			email,
			username,
			firstName,
			lastName,
			password,
			confirmPassword,
			country,
			postCode,
			favouriteTeam,
			xflNews,
			isTicketsOptIn,
		} = event.currentTarget;

		if (!this._formValidator.isValid) {
			return;
		}

		if (password.value !== confirmPassword.value) {
			this._formValidator.setError("confirmPassword", "Passwords don't match");
			return;
		}

		const payload: IRegistrationPayload = {
			country: country.value,
			email: email.value,
			favouriteTeam: Number(favouriteTeam.value),
			firstName: firstName.value,
			isTicketsOptIn: isTicketsOptIn.checked,
			lastName: lastName.value,
			password: password.value,
			postCode: postCode?.value,
			username: username.value,
			xflNews: xflNews.checked,
		};

		this._isLoading = true;
		this._userStore
			.register(payload)
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	public handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.value) {
			return;
		}
		const isPostcodeVisible = POSTCODE_COUNTRIES.includes(event.target.value);
		this._isPostcodeVisible = isPostcodeVisible;
		if (!isPostcodeVisible) {
			this._formValidator.clearError("postCode");
		}
	};

	public goToLogin = () => {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	@action
	protected onSuccess() {
		this.clearErrors();
		this._modalsStore.hideModal();
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while register, please again later";
			return;
		}

		const errorMessage = getErrorMessageFromAxiosResponse(error);
		if (errorMessage.includes("not contain profanities")) {
			this._formValidator.setError("username", errorMessage);
			return;
		}

		this._error = errorMessage;
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected clearErrors() {
		this._formValidator.clearErrors();
		this._error = undefined;
	}
}
