import {action, IReactionDisposer, makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {Empty} from "data/types/generics";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {IContest, IGameCard, IGameCardProp} from "data/types/entities";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getDashedValue} from "data/utils/helpers";
import {ContestStatus, ModalType} from "data/enums";
import {NO_BINGO_BOARD_GENERATED_CODE} from "data/constants";
import {last} from "lodash";

export interface IGamePlayCardController extends ViewController {
	getPropByPosition: (position: number) => Empty<IGameCardProp>;

	openTutorial: () => void;

	get homeSquad(): Empty<ISquad>;

	get awaySquad(): Empty<ISquad>;

	get gameCard(): Empty<IGameCard>;

	get contestId(): Empty<number>;

	get isContestFinal(): boolean;

	get isLoading(): boolean;

	get eventName(): string;
}

@injectable()
export class GamePlayCardController implements IGamePlayCardController {
	protected _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get awaySquad(): Empty<ISquad> {
		return this._squadStore.getSquadById(this.contest?.awaySquad);
	}

	get homeSquad(): Empty<ISquad> {
		return this._squadStore.getSquadById(this.contest?.homeSquad);
	}

	get contestId(): Empty<number> {
		return this._gameplayStore.selectedContest?.id;
	}

	get gameCard(): Empty<IGameCard> {
		return this._gameplayStore.gameCard;
	}

	get eventName(): string {
		return getDashedValue(this.contest?.name);
	}

	get isContestFinal(): boolean {
		return this.contestId === this.finalContest?.id;
	}

	protected get contest(): Empty<IContest> {
		return this._gameplayStore.selectedContest;
	}

	protected get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	protected get finalContest(): Empty<IContest> {
		if (this._gameplayStore.contests.length < 3) {
			return undefined;
		}
		return last(this._gameplayStore.contests.slice(0, 3));
	}

	dispose(): void {
		this._subscriptions$.forEach((subscription) => subscription());
	}

	init(param: void): void {
		if (this.isAuthorized) {
			this.fetchBoardForContest();
			this.checkIsClaimedModalViewed();
		}
		const subscription = reaction(
			() => [this.contest, this.isAuthorized],
			() => {
				if (this.contest && this.isAuthorized) {
					this.fetchBoardForContest();
				}
			}
		);
		const gameBarSubscription = reaction(
			() => [this.contest, this._gameBarStore.gameBar],
			() => this.checkIsClaimedModalViewed()
		);
		this._subscriptions$.push(subscription);
		this._subscriptions$.push(gameBarSubscription);
	}

	public getPropByPosition = (position: number) => {
		return this.gameCard?.items.find((e) => e.position === position);
	};

	public openTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};

	@action
	protected fetchBoardForContest(): void {
		this._isLoading = true;
		this._gameplayStore
			.fetchGameCardForCurrentContest()
			.catch(this.onFetchDashboardFailed.bind(this))
			.finally(this.onFinally.bind(this));
	}

	protected onFetchDashboardFailed(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		const isNoBoardResponse = error?.response?.status === NO_BINGO_BOARD_GENERATED_CODE;
		if (
			isNoBoardResponse &&
			this._gameplayStore.selectedContest?.status !== ContestStatus.Open
		) {
			return;
		}

		if (!isNoBoardResponse) {
			this._modalsStore.showAxiosError(error);
		}
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	protected checkIsClaimedModalViewed(): void {
		if (!this.isAuthorized) {
			return;
		}
		if (this._gameBarStore.getIsClaimModalShouldBeShown()) {
			this._modalsStore.showModal(ModalType.CLAIM_BINGO);
		}
		this.checkGotBingoModal();
	}

	protected checkGotBingoModal(): void {
		const shouldBeViewed = this._gameBarStore.getIsGotBingoModalShouldBeShown();
		if (shouldBeViewed) {
			this._modalsStore.showModal(ModalType.GOT_BINGO);
		}
	}
}
