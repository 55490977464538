import React from "react";
import {observer} from "mobx-react";
import {Button, Checkbox, FormControlLabel, Modal, Typography} from "@mui/material";
import {ModalContent, ModalHeadingImage} from "views/components/common/modal";
import styled from "@emotion/styled";

import BingoModalHeading from "assets/img/bingo-modal-heading.svg";
import {useViewController} from "data/services/locator";
import {IModalTermsController} from "views/components/modals/modalTerms/modalTerms.controller";
import {Bindings} from "data/constants/bindings";

const Form = styled.form`
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

export const ModalTerms: React.FC = observer(() => {
	const {isLoading, isOpen, handleFormSubmit} = useViewController<IModalTermsController>(
		Bindings.ModalTermsController
	);
	return (
		<Modal open={isOpen} className="terms">
			<ModalContent className="no-padding">
				<ModalHeadingImage src={BingoModalHeading} alt="heading" />
				<Form onSubmit={handleFormSubmit}>
					<Typography variant="h3">
						<span>Already have an XFL</span>
						<br />
						<span>Pick'Em Account?</span>
					</Typography>

					<Typography variant="body1Light">
						Accept the terms and conditions for XFL Bingo to merge your account.
					</Typography>
					<FormControlLabel
						control={<Checkbox name="terms" required />}
						label={
							<Typography variant="body1Light">
								By selecting this box I agree to the{" "}
								<a
									href="/help/terms"
									className="underline"
									target="_blank"
									rel="noreferrer">
									Terms and Conditions
								</a>
							</Typography>
						}
					/>
					<Button fullWidth type="submit" disabled={isLoading}>
						Continue
					</Button>
				</Form>
			</ModalContent>
		</Modal>
	);
});
