import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {ShareUtility} from "data/utils/ShareUtility";
import {SocialNetwork} from "data/enums";

export interface ISocialSharingController extends ViewController {
	openPopover: () => void;
	closePopover: () => void;
	shareFacebook: () => void;
	shareTwitter: () => void;
	shareNative: () => void;

	get isPopoverOpen(): boolean;
}

@injectable()
export class SocialSharingController implements ISocialSharingController {
	constructor() {
		makeAutoObservable(this);
	}

	@observable private _isPopoverOpen: boolean = false;

	get isPopoverOpen(): boolean {
		return this._isPopoverOpen;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public closePopover = (): void => {
		this._isPopoverOpen = false;
	};

	@action
	public openPopover = (): void => {
		this._isPopoverOpen = true;
	};

	public shareFacebook = (): void => {
		ShareUtility.share({
			socialNetwork: SocialNetwork.FACEBOOK,
		});
	};

	public shareNative = (): void => {
		ShareUtility.share({
			socialNetwork: SocialNetwork.MOBILE,
			message: "Join me on XFL boXes this postseason for a chance to win big!",
		});
	};

	public shareTwitter = (): void => {
		ShareUtility.share({
			socialNetwork: SocialNetwork.TWITTER,
			message: "Join me on XFL boXes this postseason for a chance to win big!",
		});
	};
}
