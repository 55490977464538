import {action, makeAutoObservable} from "mobx";
import {injectable} from "inversify";

export interface IMenuStore {
	get isMenuOpen(): boolean;
	toggleMenu(): void;
	closeMenu(): void;
	openMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	protected _isMenuOpen: boolean = false;
	constructor() {
		makeAutoObservable(this);
	}

	@action
	public openMenu(): void {
		this._isMenuOpen = true;
	}
	@action
	public closeMenu(): void {
		this._isMenuOpen = false;
	}

	get isMenuOpen(): boolean {
		return this._isMenuOpen;
	}

	@action
	public toggleMenu(): void {
		this._isMenuOpen = !this._isMenuOpen;
	}
}
