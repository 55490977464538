import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IAuthApiProvider, ILoginPayload} from "data/providers/api/auth.api.provider";
import {Bindings} from "data/constants/bindings";
import type {IRegistrationPayload, IResetPasswordPayload} from "data/types/entities";

export interface IUser {
	id: number;
	country: string;
	createdAt: string;
	email: string;
	favouriteTeam: number;
	firstName: string;
	isNotificationsEnabled: boolean;
	isPlayed: boolean;
	isTicketsOptIn: boolean;
	lastName: string;
	postCode: string;
	username: string;
	isTutorialViewedBingo: boolean;
	postseasonModalBingo: boolean;

	// For update password functionality
	password?: string;
}

export interface IUserStore {
	get user(): IUser | undefined;

	get fullName(): string;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	login(payload: ILoginPayload): Promise<void>;

	register(payload: IRegistrationPayload): Promise<void>;

	logout(): Promise<void>;

	forgotPassword(email: string): Promise<void>;

	resetPassword(payload: IResetPasswordPayload): Promise<void>;

	fetchUser(): Promise<void>;

	registerForGame(): Promise<void>;

	updateUser(payload: Partial<IUser>): Promise<void>;

	setTutorialViewed(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	constructor(@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUser = undefined;

	get user() {
		return this._user;
	}

	@observable private _isAuthorized = false;

	get isAuthorized(): boolean {
		return this._isAuthorized;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut(): boolean {
		return this._wasLoggedOut;
	}

	@computed get fullName() {
		if (!this._user) {
			return "";
		}

		const {firstName, lastName} = this._user;
		return `${firstName} ${lastName}`;
	}

	@action
	async login(payload: ILoginPayload): Promise<void> {
		try {
			const response = await this._authApi.login(payload);
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				this._isAuthorized = true;
				this._wasLoggedOut = false;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});
		window.location.href = "/";
	}

	@action
	public async register(payload: IRegistrationPayload): Promise<void> {
		try {
			const {data} = await this._authApi.register(payload);

			runInAction(() => {
				this._user = data.success.user;
				this._isAuthorized = true;
				this._wasLoggedOut = false;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async forgotPassword(email: string): Promise<void> {
		try {
			await this._authApi.forgotPassword(email);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async resetPassword(payload: IResetPasswordPayload): Promise<void> {
		try {
			await this._authApi.resetPassword(payload);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async fetchUser(): Promise<void> {
		try {
			const {data} = await this._authApi.user();

			runInAction(() => {
				this._user = data.success.user;
				this._isAuthorized = true;
				this._wasLoggedOut = false;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public async registerForGame(): Promise<void> {
		try {
			await this._authApi.registerForGame();

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async updateUser(payload: Partial<IUser>): Promise<void> {
		try {
			const {data} = await this._authApi.updateUser(payload);
			runInAction(() => {
				this._user = data.success.user;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async setTutorialViewed(): Promise<void> {
		try {
			const {data} = await this._authApi.updateUser({isTutorialViewedBingo: true});
			runInAction(() => {
				this._user = data.success.user;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
