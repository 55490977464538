import React from "react";
import {observer} from "mobx-react";
import {AuthContainer} from "views/components/common/layouts";
import {ModalCloseButton} from "views/components/common/modal";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "assets/img/header-logo.png";
import {Modal, Typography} from "@mui/material";
import {FormLogin} from "views/components/formLogin/formLogin.component";
import {useViewController} from "data/services/locator";
import {IModalLoginController} from "views/components/modals/modalLogin/modalLogin.controller";
import {Bindings} from "data/constants/bindings";

export const ModalLogin: React.FC = observer(() => {
	const {isOpen, close} = useViewController<IModalLoginController>(Bindings.ModalLoginController);
	return (
		<Modal open={isOpen}>
			<AuthContainer>
				<ModalCloseButton color="modalClose" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<img src={Logo} alt="logo" />
				<h1>LOG IN TO YOUR ACCOUNT</h1>
				<Typography variant="body1" className="desc">
					Already have an XFL Pick’Em account? Login using your same email and password.
				</Typography>
				<FormLogin />
			</AuthContainer>
		</Modal>
	);
});
