import {AbstractValidator} from "data/utils/validators/abstract.validator";
import {THTMLFormElements} from "data/types/entities";

export class UsernameValidator extends AbstractValidator {
	getErrorMessage(): string {
		return "Display Name may not include an '@' symbol";
	}

	validate(formControl: THTMLFormElements): boolean {
		return !/@/gi.test(formControl.value);
	}
}
