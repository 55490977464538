import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {ModalType} from "data/enums";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import type {Empty} from "data/types/generics";
import {
	IContest,
	ICreateBingoBoardPayload,
	ICreateBingoBoardSquadsVersus,
} from "data/types/entities";
import {SQUAD_PROP_POSITION} from "data/constants";

export interface IModalCreateSingleBingoBoardController extends ViewController {
	generateBoard: () => void;
	selectSquad: (data: ICreateBingoBoardPayload) => void;
	close: () => void;

	get isSubmitDisabled(): boolean;

	get contest(): Empty<IContest>;

	get squads(): Empty<ICreateBingoBoardSquadsVersus>;

	get isOpen(): boolean;

	get isLoading(): boolean;

	get selectedSquad(): Empty<number>;
}

@injectable()
export class ModalCreateSingleBingoBoardController
	implements IModalCreateSingleBingoBoardController
{
	constructor(
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _selectedSquad: Empty<number>;

	get selectedSquad(): Empty<number> {
		return this._selectedSquad;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.CREATE_SINGLE_BINGO_BOARD);
	}

	get contest(): Empty<IContest> {
		return this._gameplayStore.selectedContest;
	}

	get isSubmitDisabled(): boolean {
		return this._isLoading || !this._selectedSquad;
	}

	get squads(): Empty<ICreateBingoBoardSquadsVersus> {
		if (!this.selectedContest) {
			return undefined;
		}
		const contest = this.selectedContest;
		return {
			homeSquad: this._squadStore.getSquadById(contest.homeSquad),
			awaySquad: this._squadStore.getSquadById(contest.awaySquad),
			contestId: contest.id,
		};
	}

	protected get selectedContest(): Empty<IContest> {
		return this._gameplayStore.selectedContest;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.checkSelectedSquad();
		reaction(
			() => [this.isOpen, this._gameplayStore.selectedContest],
			() => this.checkSelectedSquad()
		);
	}

	public generateBoard = (): void => {
		if (!this._gameplayStore.selectedContest || !this._selectedSquad) {
			return;
		}

		const payload: ICreateBingoBoardPayload = {
			contestId: this._gameplayStore.selectedContest.id,
			squadId: this.selectedSquad,
		};
		this._isLoading = true;
		this._gameplayStore
			.updateBingoBoard(payload)
			.then(this.onSuccess.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	public selectSquad = (data: ICreateBingoBoardPayload): void => {
		this._selectedSquad = data.squadId;
	};

	@action
	public close = () => {
		this._selectedSquad = undefined;
		this._modalsStore.hideModal(ModalType.CREATE_SINGLE_BINGO_BOARD);
	};

	@action
	protected onSuccess = () => {
		this._selectedSquad = undefined;
		this._modalsStore.hideModal();
	};

	@action
	protected onFinally = () => {
		this._isLoading = false;
	};

	protected checkSelectedSquad() {
		if (!this.isOpen || !this._gameplayStore.selectedContest) {
			return;
		}
		const board = this._gameplayStore.gameCard;
		const squadProp = board?.items.find((e) => e.position === SQUAD_PROP_POSITION);
		const prop = this._gameplayStore.getPropById(squadProp?.propId);

		if (!prop) {
			return;
		}
		this._selectedSquad = prop.squadId ?? undefined;
	}
}
