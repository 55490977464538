import {THTMLFormElements} from "data/types/entities";
import {AbstractValidator} from "data/utils/validators/abstract.validator";

export class PostcodeValidator extends AbstractValidator {
	getErrorMessage(): string {
		return "Invalid ZIP Code";
	}

	validate(formControl: THTMLFormElements): boolean {
		return /^[0-9]{5}$/.test(formControl.value);
	}
}
