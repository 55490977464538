import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {Empty} from "data/types/generics";
import {IContestProp} from "data/types/entities";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import {PropCorrect, PropStatus} from "data/enums";

interface IParams {
	propId: Empty<number>;
}

export interface ICardPropController extends ViewController<IParams> {
	get isAuthorized(): boolean;

	get prop(): Empty<IContestProp>;

	passNewPropId: (propId: Empty<number>) => void;

	get propClass(): string;
	// get isPropCorrect(): boolean;
	// get isPropIncorrect(): boolean
}

@injectable()
export class CardPropController implements ICardPropController {
	protected _propId: Empty<number>;

	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get prop(): Empty<IContestProp> {
		return this._gameplayStore.getPropById(this._propId);
	}

	get propClass(): string {
		let className = "";
		if (!this.prop || this.prop.status === PropStatus.Open) {
			return className;
		}

		switch (this.prop.result) {
			case PropCorrect.CORRECT:
				className += "correct";
				break;
			case PropCorrect.INCORRECT:
				className += "incorrect";
				break;
		}
		return className;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._propId = param.propId;
	}

	@action
	public passNewPropId = (propId: Empty<number>) => {
		this._propId = propId;
	};
}
