import React from "react";
import styled from "@emotion/styled";
import footerBackground from "assets/img/footer.svg";
import geniusLogo from "assets/img/logo_genius.svg";
import {Typography} from "@mui/material";

const FooterElement = styled.footer`
	background: url(${footerBackground});
	background-size: cover;
	height: 144px;
	padding: 16px 24px;
	width: 100%;
	text-align: center;
	margin-top: auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const Rights = styled.div`
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.color.brand.white};
	}
`;

const Links = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	a {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.color.brand.white};
		text-transform: capitalize;

		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
		}
	}
`;

const GeniusLogo = styled.img`
	height: 32px;
	width: auto;
`;

export const Footer: React.FC = () => {
	return (
		<FooterElement>
			<Links>
				<a href="https://www.xfl.com/terms-and-conditions" target="_blank" rel="noreferrer">
					<Typography variant="body1">Terms of Use</Typography>
				</a>
				<a
					href="https://www.xfl.com/privacy-policy#:~:text=We%20collect%20your%20email%20address,websites%20and%20social%20media%20channels."
					target="_blank"
					rel="noreferrer">
					<Typography variant="body1">Privacy Policy</Typography>
				</a>
			</Links>

			<Rights>
				<Typography variant="caption">© 2023 XFL Properties LLC.</Typography>
				<br />
				<Typography variant="caption">All Rights Reserved.</Typography>
			</Rights>

			<GeniusLogo src={geniusLogo} alt="Genius Logo" />
		</FooterElement>
	);
};
