import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {IS_SECRET_ENABLED} from "data/constants";

export interface IHOCSecretKeyController extends ViewController {
	handleSecretClick: () => void;

	get isVisible(): boolean;
}

@injectable()
export class HOCSecretKeyController implements IHOCSecretKeyController {
	protected readonly secretKey = "bng_sk";
	@observable protected _clicks = 0;

	constructor() {
		makeAutoObservable(this);
	}

	get isVisible(): boolean {
		if (!IS_SECRET_ENABLED) {
			return false;
		}
		return !this.isSecretPassed();
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public handleSecretClick = (): void => {
		this._clicks++;
		if (this._clicks >= 7) {
			localStorage.setItem(this.secretKey, "true");
			window.location.reload();
		}
	};

	protected isSecretPassed(): boolean {
		return JSON.parse(localStorage.getItem(this.secretKey) || "false") as boolean;
	}
}
