import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button, InputAdornment, TextField, Typography} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IFormLoginController} from "views/components/formLogin/formLogin.controller";
import {Bindings} from "data/constants/bindings";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Exist} from "views/components/exist/exist.component";

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	gap: 16px;

	> div {
		width: 100%;
	}

	.forgot-password {
		margin-left: auto;
	}
`;

export const FormLogin: React.FC = observer(() => {
	const {
		isLoading,
		isPasswordVisible,
		error,
		handleFormSubmit,
		togglePasswordVisibility,
		goToForgotPassword,
		goToRegister,
	} = useViewController<IFormLoginController>(Bindings.FormLoginController);
	return (
		<Form onSubmit={handleFormSubmit}>
			<Exist when={Boolean(error)}>
				<Typography variant="error">{error}</Typography>
			</Exist>

			<TextField
				name="email"
				label="Email"
				placeholder="Email"
				type="email"
				required
				InputLabelProps={{shrink: true}}
			/>
			<TextField
				name="password"
				label="Password"
				placeholder="Password"
				type={isPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button
								color="adornment"
								onClick={togglePasswordVisibility}
								aria-label="toggle password visibility">
								{isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
							</Button>
						</InputAdornment>
					),
				}}
				required
				InputLabelProps={{shrink: true}}
			/>
			<Button className="forgot-password" color="link" onClick={goToForgotPassword}>
				<Typography variant="body1">Forgot password?</Typography>
			</Button>
			<Button fullWidth type="submit" disabled={isLoading}>
				Login
			</Button>
			<Typography variant="body1">
				<span>New?</span>
				<span>&nbsp;</span>
				<Button color="link" onClick={goToRegister}>
					Register Now
				</Button>
			</Typography>
		</Form>
	);
});
