import React from "react";
import {CircularProgress} from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	&.fixed {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #ffffff;
		z-index: 1000;
	}

	&.container {
		padding: 24px;
		margin: 0 auto;
	}

	svg {
		color: ${({theme}) => theme.color.brand.primary};
	}

	&.button {
		svg {
			transform: scale(0.6);
		}
	}
`;

interface IProps {
	fixed?: boolean;
	button?: boolean;
	container?: boolean;
}

export const Preloader: React.FC<IProps> = ({fixed, button, container}) => {
	const fixedClass = fixed ? "fixed" : "";
	const buttonClass = button ? "button" : "";
	const containerClass = container ? "container" : "";
	return (
		<Wrapper className={`${fixedClass} ${buttonClass} ${containerClass} preloader`}>
			<CircularProgress />
		</Wrapper>
	);
};
