import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILiveScoringStore} from "data/stores/liveScoring/livescoring.store";

export interface IGamePlayController extends ViewController {
	get isAuthorized(): boolean;
}

@injectable()
export class GamePlayController implements IGamePlayController {
	constructor(
		@inject(Bindings.LiveScoringStore) private _liveScoringStore: ILiveScoringStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		this._liveScoringStore.unsubscribeLiveScoring();
	}

	init(param: void): void {
		this._liveScoringStore.subscribeLiveScoring();
	}
}
