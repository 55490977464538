import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayApiProvider} from "data/providers/api/gameplay.api.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IGameBarEntity} from "data/types/entities";
import {IAxiosApiErrorGeneral} from "data/types/api";
import {ContestStatus} from "data/enums";
import {Empty} from "data/types/generics";

export interface IGameBarStore {
	get gameBar(): IGameBarEntity[];

	get liveGameBar(): Empty<IGameBarEntity>;

	getGameBarEntityByContestId(contestId: Empty<number>): Empty<IGameBarEntity>;

	getIsClaimModalShouldBeShown(): boolean;

	getIsGotBingoModalShouldBeShown(): boolean;

	getIsLiveGameBarBingoShapeX(): boolean;

	fetchGameBar(): Promise<void>;

	setClaimModalViewed(contestId: Empty<number>): Promise<void>;

	setBingoModalViewed(contestId: Empty<number>): Promise<void>;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	constructor(
		@inject(Bindings.GamePlayApiProvider) private _gamePlayApiProvider: IGamePlayApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable protected _gameBar: IGameBarEntity[] = [];

	get gameBar(): IGameBarEntity[] {
		return this._gameBar;
	}

	get liveGameBar(): Empty<IGameBarEntity> {
		return this._gameBar.find((e) => e.status === ContestStatus.Live);
	}

	public getGameBarEntityByContestId(contestId: Empty<number>) {
		return this._gameBar.find((e) => e.contestId === contestId);
	}

	public getIsClaimModalShouldBeShown() {
		const gameBars = this._gameBar.filter(
			(e) => e.status === ContestStatus.Complete && !e.displayedModal
		);
		const noBingo = gameBars.every((e) => !e.entriesWon);

		if (noBingo) {
			return false;
		}

		return gameBars.length > 0;
	}

	public getIsGotBingoModalShouldBeShown(): boolean {
		if (!this.liveGameBar || !this.liveGameBar.displayedModalBingo) {
			return false;
		}

		return Object.values(this.liveGameBar.displayedModalBingo).some((viewed) => !viewed);
	}

	public getIsLiveGameBarBingoShapeX(): boolean {
		if (!this.liveGameBar || !this.liveGameBar.displayedModalBingo) {
			return false;
		}

		return Object.entries(this.liveGameBar.displayedModal!).some(([key, value], i) => {
			return /\d-\d-\d-\d-\d/gm.test(key) && value === false;
		});
	}

	public async fetchGameBar(): Promise<void> {
		try {
			const {data} = await this._gamePlayApiProvider.gameBar();

			runInAction(() => {
				this._gameBar = data.success.contests;
			});

			return Promise.resolve();
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
			return Promise.reject(e);
		}
	}

	public async setClaimModalViewed(contestId: Empty<number>): Promise<void> {
		if (!contestId) {
			return Promise.reject("Invalid contest ID for view Claim Bingo modal");
		}
		try {
			await this._gamePlayApiProvider.viewClaimModal(contestId);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async setBingoModalViewed(contestId: Empty<number>): Promise<void> {
		if (!contestId) {
			return Promise.reject("Invalid contest ID for view Bingo modal");
		}
		try {
			await this._gamePlayApiProvider.viewBingoModalForContest(contestId);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
