export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum Language {
	English = "en-US",
}

export enum ModalType {
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	ERROR,
	SUCCESS,
	TUTORIAL,
	REGISTRATION,
	PRE_REGISTRATION,
	LOGIN,
	TERMS,
	CREATE_BINGO_BOARD,
	CREATE_SINGLE_BINGO_BOARD,
	CLAIM_BINGO,
	GOT_BINGO,
	POST_SEASON,
}

export enum PropCorrect {
	CORRECT = "correct",
	INCORRECT = "incorrect",
}

export enum PropStatus {
	Open = "open",
	Live = "live",
	Complete = "complete",
}
export enum ContestStatus {
	Draft = "draft",
	Open = "open",
	Live = "live",
	Complete = "complete",
	Canceled = "canceled",
}

export enum ShareType {
	General = "general",
}

export enum SocialNetwork {
	FACEBOOK,
	TWITTER,
	MOBILE,
}
