import React from "react";
import {observer} from "mobx-react";
import {
	Button,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IModalPreRegistrationController} from "views/components/modals/modalPreRegistration/modalPreRegistration.controller";
import {Bindings} from "data/constants/bindings";
import BingoModalHeading from "assets/img/bingo-modal-heading.svg";
import {ModalCloseButton, ModalContent, ModalHeadingImage} from "views/components/common/modal";
import styled from "@emotion/styled";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";

const Form = styled.form`
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	.forgot-password {
		margin-left: auto;
	}
`;

export const ModalPreRegistration: React.FC = observer(() => {
	const {
		isOpen,
		isLoading,
		isPasswordVisible,
		error,
		handleFormSubmit,
		togglePasswordVisibility,
		goToForgotPassword,
		close,
		goToRegister,
	} = useViewController<IModalPreRegistrationController>(Bindings.ModalPreRegistrationController);

	return (
		<Modal open={isOpen}>
			<ModalContent className="no-padding">
				<ModalHeadingImage src={BingoModalHeading} alt="heading" />
				<ModalCloseButton className="highlight" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<Form onSubmit={handleFormSubmit}>
					<Typography variant="h3">
						<span>Already have an XFL</span>
						<br />
						<span>Pick'Em Account?</span>
					</Typography>

					<Exist when={Boolean(error)}>
						<Typography variant="error">{error}</Typography>
					</Exist>

					<Typography variant="body1Light">
						Login using your same email and password:
					</Typography>

					<TextField
						fullWidth
						name="email"
						label="Email"
						placeholder="Email"
						type="email"
						required
						InputLabelProps={{shrink: true}}
					/>
					<TextField
						fullWidth
						name="password"
						label="Password"
						placeholder="Password"
						type={isPasswordVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Button
										color="adornment"
										onClick={togglePasswordVisibility}
										aria-label="toggle password visibility">
										{isPasswordVisible ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</Button>
								</InputAdornment>
							),
						}}
						required
						InputLabelProps={{shrink: true}}
					/>
					<Button className="forgot-password" color="link" onClick={goToForgotPassword}>
						<Typography variant="body1">Forgot password?</Typography>
					</Button>

					<FormControlLabel
						control={<Checkbox name="terms" required />}
						label={
							<Typography variant="body1Light">
								By selecting this box I agree to the{" "}
								<a
									href="/help/terms"
									className="underline"
									target="_blank"
									rel="noreferrer">
									Terms and Conditions
								</a>
							</Typography>
						}
					/>
					<Button fullWidth type="submit" disabled={isLoading}>
						Continue
					</Button>
					<Typography variant="body1">
						<span>New?</span>
						<span>&nbsp;</span>
						<Button color="link" onClick={goToRegister}>
							Register Now
						</Button>
					</Typography>
				</Form>
			</ModalContent>
		</Modal>
	);
});
