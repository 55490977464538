import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {
	ICreateBingoBoardPayload,
	IGameBar,
	IGameCardResponse,
	IGenerateGameCardsPayload,
	IMultipleGameCardsResponse,
} from "data/types/entities";

export interface IGamePlayApiProvider {
	gameCard: (
		payload: ICreateBingoBoardPayload
	) => Promise<AxiosResponse<IApiResponse<IGameCardResponse>>>;

	gameBar: () => Promise<AxiosResponse<IApiResponse<IGameBar>>>;

	viewClaimModal: (contestId: number) => Promise<AxiosResponse<unknown>>;
	viewBingoModalForContest: (contestId: number) => Promise<AxiosResponse<unknown>>;

	updateGameCard: (
		payload: ICreateBingoBoardPayload
	) => Promise<AxiosResponse<IApiResponse<IGameCardResponse>>>;

	multipleGameCards: (
		payload: IGenerateGameCardsPayload
	) => Promise<AxiosResponse<IApiResponse<IMultipleGameCardsResponse>>>;
}

@injectable()
export class GamePlayApiProvider implements IGamePlayApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	multipleGameCards = (
		payload: IGenerateGameCardsPayload
	): Promise<AxiosResponse<IApiResponse<IMultipleGameCardsResponse>>> => {
		return this._http.post("en/game_card/generate", payload);
	};

	gameCard = (
		payload: ICreateBingoBoardPayload
	): Promise<AxiosResponse<IApiResponse<IGameCardResponse>>> => {
		const {contestId, squadId} = payload;
		if (squadId) {
			return this._http.get(`en/game_card/${contestId}/${squadId}`);
		}
		return this._http.get(`en/game_card/${contestId}`);
	};

	updateGameCard = (
		payload: ICreateBingoBoardPayload
	): Promise<AxiosResponse<IApiResponse<IGameCardResponse>>> => {
		return this._http.post("en/game_card/change_team", payload);
	};

	gameBar = (): Promise<AxiosResponse<IApiResponse<IGameBar>>> => {
		return this._http.get("en/gamebar");
	};

	viewBingoModalForContest(contestId: number): Promise<AxiosResponse<unknown>> {
		return this._http.post(`en/gamebar/modal_bingo/${contestId}`);
	}

	viewClaimModal(contestId: number): Promise<AxiosResponse<unknown>> {
		return this._http.post(`en/gamebar/modal_viewed/${contestId}`);
	}
}
