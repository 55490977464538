import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import React from "react";
import {IFormLogin} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import type {Empty} from "data/types/generics";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";

export interface IModalPreRegistrationController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IFormLogin>) => void;
	togglePasswordVisibility: () => void;
	goToForgotPassword: () => void;
	close: () => void;
	goToRegister: () => void;

	get error(): Empty<string>;

	get isLoading(): boolean;

	get isOpen(): boolean;

	get isPasswordVisible(): boolean;
}

@injectable()
export class ModalPreRegistrationController implements IModalPreRegistrationController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.PRE_REGISTRATION);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public goToForgotPassword = (): void => {
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);
	};

	public handleFormSubmit = (event: React.SyntheticEvent<IFormLogin>): void => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		const payload: ILoginPayload = {
			email: email.value,
			password: password.value,
		};
		this.login(payload);
	};

	public togglePasswordVisibility = (): void => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	public close = (): void => {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	public goToRegister = (): void => {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	@action
	protected login(payload: ILoginPayload): void {
		this._isLoading = true;

		this._userStore
			.login(payload)
			.then(this.onLoginSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	}

	@action
	protected onLoginSuccess() {
		this._isLoading = true;
		this._userStore
			.registerForGame()
			.then(this.onRegisterSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while register, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	protected onRegisterSuccess() {
		this._modalsStore.hideModal();
	}
}
