import {createTheme} from "@mui/material/styles";
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {colorsTheme, paletteTheme} from "assets/theming/palette.theme";
import {inputsTheme} from "assets/theming/inputs.theme";
import {checkboxTheme} from "assets/theming/checkbox.theme";
import {switchTheme} from "assets/theming/switch.theme";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject: ThemeOptions & typeof colorsTheme = {
	...colorsTheme,
};

// Please note that typography should be included after components to override fonts
type ITheme = typeof themeObject;
export const theme = createTheme(
	buttonsTheme,
	paletteTheme,
	inputsTheme,
	checkboxTheme,
	switchTheme,
	typographyTheme
);

declare module "@mui/material/styles" {
	/**
	 *Disabled as it's allowing to avoid `any` type per MUI documentation
	 * https://mui.com/material-ui/customization/theming/#custom-variables
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}

declare module "@emotion/react" {
	/**
	 *Disabled as it's allowing to avoid `any` type per Emotion documentation
	 * https://emotion.sh/docs/typescript#define-a-theme
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}
