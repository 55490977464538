import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IStaticContentStore} from "data/stores/static_content/static_content.store";

export interface IModalPostSeasonController extends ViewController {
	get isOpen(): boolean;
}

@injectable()
export class ModalPostSeasonController implements IModalPostSeasonController {
	constructor(
		@inject(Bindings.StaticContentStore) private _staticContentStore: IStaticContentStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isOpen(): boolean {
		return this._staticContentStore.settings?.periodStatus === "postseason";
	}
}
