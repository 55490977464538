import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {
	Button,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useViewController} from "data/services/locator";
import {IFormRegisterController} from "views/components/formRegister/formRegister.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	gap: 16px;

	> div {
		width: 100%;
	}
`;

export const FormRegister: React.FC = observer(() => {
	const {
		isPasswordVisible,
		isConfirmPasswordVisible,
		isLoading,
		isPostcodeVisible,
		squads,
		countries,
		formErrors,
		error,
		goToLogin,
		togglePasswordVisibility,
		toggleConfirmPasswordVisibility,
		handleCountryChange,
		handleFormChange,
		handleFormSubmit,
	} = useViewController<IFormRegisterController>(Bindings.FormRegisterController);
	return (
		<Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
			<TextField
				name="email"
				label="Email"
				placeholder="Email"
				type="email"
				required
				InputLabelProps={{shrink: true}}
			/>
			<TextField
				name="username"
				label="Display Name"
				placeholder="Display Name"
				required
				error={Boolean(formErrors["username"])}
				helperText={formErrors["username"]}
				InputLabelProps={{shrink: true}}
			/>
			<TextField
				name="firstName"
				label="First Name"
				placeholder="First Name"
				required
				InputLabelProps={{shrink: true}}
			/>
			<TextField
				name="lastName"
				label="Last Name"
				placeholder="Last Name"
				required
				InputLabelProps={{shrink: true}}
			/>
			<TextField
				name="password"
				label="Password"
				placeholder="Password"
				type={isPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button
								color="adornment"
								onClick={togglePasswordVisibility}
								aria-label="toggle password visibility">
								{isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
							</Button>
						</InputAdornment>
					),
				}}
				required
				InputLabelProps={{shrink: true}}
				helperText="Min 8 characters, one uppercase letter, one number, and one special character"
				error={Boolean(formErrors["password"])}
			/>
			<TextField
				name="confirmPassword"
				label="Confirm Password"
				placeholder="Confirm Password"
				required
				InputLabelProps={{shrink: true}}
				type={isConfirmPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button
								color="adornment"
								onClick={toggleConfirmPasswordVisibility}
								aria-label="toggle password visibility">
								{isConfirmPasswordVisible ? (
									<VisibilityOffIcon />
								) : (
									<VisibilityIcon />
								)}
							</Button>
						</InputAdornment>
					),
				}}
				error={Boolean(formErrors["confirmPassword"])}
				helperText={formErrors["confirmPassword"]}
			/>

			<Exist when={countries.length > 0}>
				<TextField
					select
					name="country"
					label="Country"
					required
					onChange={handleCountryChange}
					defaultValue="USA"
					InputLabelProps={{shrink: true}}>
					{countries.map((country) => (
						<MenuItem key={country.code} value={country.code}>
							{country.name}
						</MenuItem>
					))}
				</TextField>
			</Exist>

			<Exist when={isPostcodeVisible}>
				<TextField
					name="postCode"
					label="ZIP Code"
					InputLabelProps={{shrink: true}}
					placeholder="ZIP Code"
					error={Boolean(formErrors["postCode"])}
					helperText={formErrors["postCode"]}
					required
				/>
			</Exist>

			<TextField select name="favouriteTeam" label="Favorite Team" defaultValue={0}>
				<MenuItem value={0} defaultChecked>
					I'm just an XFL fan
				</MenuItem>
				{squads.map((squad) => (
					<MenuItem key={squad.id} value={squad.id}>
						{squad.name}
					</MenuItem>
				))}
			</TextField>

			<Exist when={Boolean(error)}>
				<Typography variant="error">{error}</Typography>
			</Exist>

			<FormControlLabel
				control={<Checkbox name="terms" required />}
				label={
					<span>
						By selecting this box I agree to the{" "}
						<a
							href="/help/terms"
							className="underline"
							target="_blank"
							rel="noreferrer">
							Terms and Conditions
						</a>
					</span>
				}
			/>

			<FormControlLabel
				control={<Checkbox name="xflNews" defaultChecked />}
				label={
					<span>
						I agree to receive news and special offer emails from the XFL and its
						partners
					</span>
				}
			/>

			<FormControlLabel
				control={<Checkbox name="isTicketsOptIn" />}
				label={<span>I am interested in tickets.</span>}
			/>

			<Button fullWidth type="submit" disabled={isLoading}>
				Register
			</Button>

			<Typography variant="body1">
				<span>Already have An Account?</span>
				<span> </span>
				<Button color="link" onClick={goToLogin}>
					Log In
				</Button>
			</Typography>
		</Form>
	);
});
