import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {TutorialHelper} from "data/utils/helpers/TutorialHelper";

export interface IModalWelcomeController extends ViewController {
	close: () => void;

	submit: () => void;

	get isOpen(): boolean;
}

@injectable()
export class ModalWelcomeController implements IModalWelcomeController {
	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.TUTORIAL);
	}

	protected get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.checkTutorial();
	}

	public close = () => {
		this.setTutorialViewed();
		this._modalsStore.hideModal();
	};

	public submit = () => {
		this.setTutorialViewed();
		this._modalsStore.hideModal();
		if (!this.isAuthorized) {
			this._modalsStore.showModal(ModalType.LOGIN);
		}
	};

	protected checkTutorial(): void {
		const user = this._userStore.user;
		const isTutorialViewed = TutorialHelper.getIsTutorialViewed();

		// If tutorial was viewed before registration / login or in user entity
		if (isTutorialViewed || user?.isTutorialViewedBingo) {
			this.setTutorialViewed();
			return;
		}

		// If tutorial not viewed in user;
		if (user && !user.isTutorialViewedBingo) {
			this.showTutorialModal();
			this.setTutorialViewed();
			return;
		}

		this.showTutorialModal();
		this.setTutorialViewed(false);
	}

	protected setTutorialViewed(withRequest: boolean = true): void {
		TutorialHelper.setTutorialViewed();

		if (this._userStore.user && withRequest) {
			void this._userStore.setTutorialViewed();
		}
	}

	protected showTutorialModal(): void {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	}
}
