import {IBingoHighlightProp, IMatrixLine} from "data/types/bingo";

export class BingoHighlightHelper {
	protected static readonly lineWinMatrix: IMatrixLine[] = [
		{className: "line-horizontal-1-3", line: [1, 2, 3]},
		{className: "line-horizontal-4-6", line: [4, 5, 6]},
		{className: "line-horizontal-7-9", line: [7, 8, 9]},
		{className: "line-vertical-1-7", line: [1, 4, 7]},
		{className: "line-vertical-2-8", line: [2, 5, 8]},
		{className: "line-vertical-3-9", line: [3, 6, 9]},
	];

	protected static readonly crossWinMatrix: IMatrixLine[] = [
		{className: "line-cross-1-9", line: [1, 5, 9]},
		{className: "line-cross-3-7", line: [3, 5, 7]},
	];

	public static getBingoLinesClasses(props: IBingoHighlightProp[]): string[] {
		const classNames: string[] = [];
		[...this.lineWinMatrix, ...this.crossWinMatrix].forEach((matrix) => {
			const className = this.checkLineAccordingMatrix(matrix, props);
			if (className) {
				classNames.push(className);
			}
		});
		return classNames;
	}

	public static getCrossWidth(size: number): number {
		if (!size) {
			return 0;
		}
		// The diagonal of a square is greater than the side on √2
		return size * Math.sqrt(2);
	}

	protected static checkLineAccordingMatrix(
		matrix: IMatrixLine,
		props: IBingoHighlightProp[]
	): string | null {
		const matrixProps = props.filter((e) => matrix.line.includes(e.position));
		const isFullLineCorrect = matrixProps.every((e) => e.isCorrect) && matrixProps.length === 3;

		if (isFullLineCorrect) {
			return matrix.className;
		}
		return null;
	}
}
