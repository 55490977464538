import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IContestProp} from "data/types/entities";
import {getImageFromBackend} from "data/utils/helpers";
import {ContestStatus, ModalType, PropCorrect, PropStatus} from "data/enums";
import React from "react";

interface IParams {
	propId: Empty<number>;
}

export interface ICardPropSquadController extends ViewController<IParams> {
	passNewPropId: (propId: Empty<number>) => void;
	reCreateContest: (event: React.SyntheticEvent<HTMLDivElement>) => void;
	closePopover: () => void;

	get isPopoverVisible(): boolean;

	get isAuthorized(): boolean;

	get prop(): Empty<IContestProp>;

	get squadImage(): string;

	get squad(): Empty<ISquad>;

	get propClass(): string;

	get isResulted(): boolean;

	get isContestCanBeRecreated(): boolean;
}

@injectable()
export class CardPropSquadController implements ICardPropSquadController {
	protected readonly popoverKey = "bng_ppvr";
	protected _propId: Empty<number>;

	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPopoverVisible = false;

	get isPopoverVisible(): boolean {
		return this._isPopoverVisible;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get prop(): Empty<IContestProp> {
		return this._gameplayStore.getPropById(this._propId);
	}

	get squadImage(): string {
		if (!this.squad) {
			return "";
		}

		const logo = this.isResulted ? this.squad.blackWhiteLogo : this.squad.logoWhiteBackground;
		return getImageFromBackend(logo);
	}

	get squad(): Empty<ISquad> {
		return this._squadStore.getSquadById(this.prop?.squadId);
	}

	get isResulted(): boolean {
		if (!this.prop) {
			return false;
		}
		return this.prop.result !== null;
	}

	get propClass(): string {
		let className = "";
		if (!this.prop || this.prop.status === PropStatus.Open) {
			return className;
		}

		switch (this.prop.result) {
			case PropCorrect.CORRECT:
				className += "correct";
				break;
			case PropCorrect.INCORRECT:
				className += "incorrect";
				break;
		}
		return className;
	}

	get isContestCanBeRecreated(): boolean {
		return this._gameplayStore.selectedContest?.status === ContestStatus.Open;
	}

	dispose(): void {
		return;
	}

	@action
	init(param: IParams): void {
		this._propId = param.propId;
		this._isPopoverVisible = !JSON.parse(localStorage.getItem(this.popoverKey) || "false");
	}

	@action
	public passNewPropId = (propId: Empty<number>) => {
		this._propId = propId;
	};

	public reCreateContest = (event: React.SyntheticEvent<HTMLDivElement>) => {
		const target = event.target as HTMLDivElement;
		/*
		 * Fix for popover, seems as it triggers onClick on ref element, that is button in this case
		 * It triggers contest recreate after click on any place of body
		 */
		if (!this.isContestCanBeRecreated || target?.classList.contains("MuiBackdrop-root")) {
			return;
		}
		this._modalsStore.showModal(ModalType.CREATE_SINGLE_BINGO_BOARD);
	};

	@action
	public closePopover = (): void => {
		localStorage.setItem(this.popoverKey, "true");
		this._isPopoverVisible = false;
	};
}
