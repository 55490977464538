import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";

export interface IContactUsController extends ViewController {
	openContactForm: () => void;
}

@injectable()
export class ContactUsController implements IContactUsController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public openContactForm = (): void => {
		if (!window.zE) {
			console.warn("no zE script found");
			return;
		}
		this.preFillForm();
		window.zE.activate();
	};

	private preFillForm() {
		if (!this._userStore.user) {
			return;
		}
		window.zE?.identify({
			email: this._userStore.user.email,
			name: this._userStore.fullName ?? "",
		});
	}
}
