import React from "react";
import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {PasswordValidator} from "data/utils/validators/PasswordValidator";
import {Bindings} from "data/constants/bindings";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IFormResetPassword, ValidationScheme} from "data/types/entities";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {ModalType} from "data/enums";

export interface IChangePasswordController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IFormResetPassword>) => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	togglePasswordVisibility: () => void;
	toggleConfirmPasswordVisibility: () => void;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get isLoading(): boolean;

	get formErrors(): IFormValidator["formErrors"];
}

@injectable()
export class ChangePasswordController implements IChangePasswordController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
	};

	constructor(
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get formErrors(): IFormValidator["formErrors"] {
		return this._formValidator.formErrors;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		const name = event.target.name;
		if (!name) {
			return;
		}
		this._formValidator.clearError(name);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IFormResetPassword>) => {
		event.preventDefault();
		this._formValidator.clearErrors();
		this._formValidator.validate(event.currentTarget);
		const {password, confirmPassword} = event.currentTarget;

		if (!this._formValidator.isValid) {
			return;
		}

		if (password.value !== confirmPassword.value) {
			this._formValidator.setError("confirmPassword", "Passwords don't match");
			return;
		}

		this._isLoading = true;
		this._userStore
			.updateUser({password: password.value})
			.then(this.onSuccess.bind(this, event.currentTarget))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};
	@action
	public toggleConfirmPasswordVisibility = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		this._modalsStore.showAxiosError(error);
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected onSuccess(form: IFormResetPassword) {
		form.reset();
		this._modalsStore.showModal(ModalType.SUCCESS, {
			title: "PASSWORD UPDATED",
			message: "You have successfully updated your password",
			buttonText: "Back to my account",
		});
	}
}
