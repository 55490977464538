import {CSSObject} from "@mui/material/styles";
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {IThemeButton} from "data/types/theme";
import {colorsTheme} from "./palette.theme";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsColorOverrides {
		adornment: true;
		modalClose: true;
		link: true;
		contestNavigation: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		textAdornment: string;
		textModalClose: string;
		textLink: string;
		textContestNavigation: string;
	}
}

function generateButtonStyle(styles: IThemeButton): CSSObject {
	const hoverState = {
		background: styles.hoverBackground,
		color: styles.hoverColor,
		borderColor: styles.hoverBorderColor,

		path: {
			fill: styles.hoverColor,
		},

		"&.stable": {
			color: "inherit",
			path: {
				color: "inherit",
				fill: "inherit",
			},
		},
	};

	return {
		background: styles.background,
		color: styles.color,
		borderColor: styles.borderColor,

		":hover": hoverState,
		":active": hoverState,
		":focus": hoverState,
		":disabled": {
			background: styles.disabledBackground,
			color: styles.disabledColor,
			borderColor: styles.disabledBorderColor,
		},

		// "@media (max-width: 640px)": {
		// 	fontSize: "16px",
		// 	padding: "6px 12px",
		// },
	};
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					transition: ".5s",
					display: "flex",
					justifyContent: "center",
					gap: "8px",
					padding: "10px 12px",
				},
				textPrimary: () => generateButtonStyle(colorsTheme.color.components.button.primary),
				outlinedPrimary: () =>
					generateButtonStyle(colorsTheme.color.components.button.secondary),
				textAdornment: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.pure),
					width: "24px",
					height: "24px",
					minWidth: "24px",
				}),
				textModalClose: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.pure),
					width: "24px",
					height: "24px",
					minWidth: "24px",
				}),
				textLink: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.pure),
					display: "inline-flex",
					textDecoration: "underline",
					padding: 0,
					textTransform: "inherit",
					fontSize: "inherit",
					fontWeight: "inherit",
					minWidth: 0,
				}),
				textContestNavigation: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.contestNavigation),
					borderRadius: "8px",
					padding: "6px 12px",

					"&.active": {
						background: colorsTheme.color.brand.white,
						color: colorsTheme.palette.primary.main,
					},
				}),
			},
		},
	},
};
