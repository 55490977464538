import {action, IReactionDisposer, makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {ContestStatus, ModalType} from "data/enums";
import {
	IContest,
	ICreateBingoBoardPayload,
	ICreateBingoBoardSquadsVersus,
	IGameBarEntity,
} from "data/types/entities";
import {Empty} from "data/types/generics";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";

export interface IModalCreateBingoBoardController extends ViewController {
	selectSquad: (params: ICreateBingoBoardPayload) => void;

	generateBoard: () => void;

	get isLoading(): boolean;

	get isSubmitDisabled(): boolean;

	get isOpen(): boolean;

	get squadsToChoose(): ICreateBingoBoardSquadsVersus[];

	get error(): Empty<string>;
}

@injectable()
export class ModalCreateBingoBoardController implements IModalCreateBingoBoardController {
	protected _selectedSquads: ICreateBingoBoardPayload[] = [];
	protected _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.CREATE_BINGO_BOARD);
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isSubmitDisabled(): boolean {
		return this.isLoading || this._selectedSquads.length < this.contestsWithNoBoardLength;
	}

	get squadsToChoose(): ICreateBingoBoardSquadsVersus[] {
		const contests = this.contestsWithNoBoard
			.map((e) => this._gameplayStore.getContestById(e.contestId))
			.filter(Boolean) as unknown as IContest[];

		return contests.map((contest) => ({
			contestId: contest.id,
			homeSquad: this._squadStore.getSquadById(contest.homeSquad),
			awaySquad: this._squadStore.getSquadById(contest.awaySquad),
		}));
	}

	protected get gameBar(): Empty<IGameBarEntity[]> {
		return this._gameBarStore.gameBar;
	}

	protected get contestsWithNoBoard(): IGameBarEntity[] {
		if (!this.gameBar) {
			return [];
		}
		return this.gameBar.filter((e) => e.status === ContestStatus.Open && !e.userHasGameCard);
	}

	protected get contestsWithNoBoardLength(): number {
		return this.contestsWithNoBoard.length;
	}

	dispose(): void {
		this._subscriptions$.forEach((subscription) => subscription());
	}

	init(param: void): void {
		const subscription$ = reaction(
			() => this.gameBar,
			() => this.checkGameCards()
		);
		this._subscriptions$.push(subscription$);
	}

	@action
	public selectSquad = (params: ICreateBingoBoardPayload) => {
		const squads = this._selectedSquads.filter((e) => e.contestId !== params.contestId);
		this._selectedSquads = [...squads, params];
	};

	@action
	public generateBoard = () => {
		if (this.isLoading) {
			return;
		}
		this._isLoading = true;
		this._gameplayStore
			.generateMultipleGameCards(this._selectedSquads)
			.then(this.onSuccess.bind(this))
			.catch(this.onError.bind(this))
			.finally(this.onFinally.bind(this));
	};

	protected checkGameCards(): void {
		if (this.contestsWithNoBoard.length > 0) {
			this._modalsStore.showModal(ModalType.CREATE_BINGO_BOARD);
		}
	}

	protected close = () => {
		this._modalsStore.hideModal();
	};

	protected onSuccess(): void {
		this._modalsStore.hideModal();
	}

	@action
	protected onError(error: AxiosError<IAxiosApiError, unknown> | undefined): void {
		if (!error) {
			this._error = "Error while register, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}

	@action
	protected onFinally(): void {
		this._isLoading = false;
	}
}
