import {ComponentType} from "react";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}
