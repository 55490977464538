import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import React from "react";
import {IFormLogin} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import {ILoginPayload} from "data/providers/api/auth.api.provider";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {Empty} from "data/types/generics";

export interface IFormLoginController extends ViewController {
	goToRegister: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IFormLogin>) => void;
	togglePasswordVisibility: () => void;
	goToForgotPassword: () => void;

	get isLoading(): boolean;

	get isPasswordVisible(): boolean;

	get error(): Empty<string>;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable protected _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public goToRegister = (): void => {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.PRE_REGISTRATION);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IFormLogin>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		const payload: ILoginPayload = {
			email: email.value,
			password: password.value,
		};

		this._isLoading = true;
		this._userStore
			.login(payload)
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	public goToForgotPassword = () => {
		// this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);
	};

	@action
	protected onSuccess() {
		this._modalsStore.hideModal();
		if (!this._userStore.user) {
			return;
		}

		const isPreRegisterModal = this._modalsStore.isModalOpen(ModalType.PRE_REGISTRATION);
		if (!this._userStore.user.isPlayed && !isPreRegisterModal) {
			this._modalsStore.showModal(ModalType.TERMS);
		}
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while register, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}
}
