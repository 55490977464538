import React from "react";
import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {Empty} from "data/types/generics";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IUserStore} from "data/stores/user/user.store";

export interface IModalTermsController extends ViewController {
	get isOpen(): boolean;

	get isLoading(): boolean;

	get error(): Empty<string>;

	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
}

@injectable()
export class ModalTermsController implements IModalTermsController {
	get error(): Empty<string> {
		return this._error;
	}
	private _error: Empty<string>;
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.TERMS);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		this._isLoading = true;
		this._userStore
			.registerForGame()
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	protected onSuccess() {
		this._modalsStore.hideModal(ModalType.TERMS);
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while register, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}
}
