import React from "react";
import {observer} from "mobx-react";
import {FormRegister} from "views/components/formRegister/formRegister.component";
import {Modal} from "@mui/material";
import {AuthContainer} from "views/components/common/layouts";
import {ModalCloseButton} from "views/components/common/modal";
import CloseIcon from "@mui/icons-material/Close";
import {useViewController} from "data/services/locator";
import {IModalRegisterController} from "views/components/modals/modalRegister/modalRegister.controller";
import {Bindings} from "data/constants/bindings";
import Logo from "assets/img/header-logo.png";

export const ModalRegister: React.FC = observer(() => {
	const {isOpen, close} = useViewController<IModalRegisterController>(
		Bindings.ModalRegisterController
	);
	return (
		<Modal open={isOpen}>
			<AuthContainer>
				<ModalCloseButton color="modalClose" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<img src={Logo} alt="logo" />
				<h1>REGISTER AN ACCOUNT</h1>
				<FormRegister />
			</AuthContainer>
		</Modal>
	);
});
