import React from "react";
import {observer} from "mobx-react";
import {ModalContent} from "views/components/common/modal";
import {Modal, Typography} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IModalPostSeasonController} from "views/components/modals/modalPostSeason/modalPostSeason.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import Logo from "assets/img/header-logo.png";

const Content = styled.div`
	padding: 20px 20px 60px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	img {
		max-width: 300px;
		padding-top: 80px;
	}
`;

export const ModalPostSeason: React.FC = observer(() => {
	const {isOpen} = useViewController<IModalPostSeasonController>(
		Bindings.ModalPostSeasonController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent className="no-padding full-screen">
				<Content>
					<img src={Logo} alt="logo" />
					<Typography variant="h3">Thanks For Playing!</Typography>
					<Typography variant="body1Light">
						Thanks for playing XFL boXes. We'll be back next year so join us for another
						shot at winning exclusive prizes.
					</Typography>
				</Content>
			</ModalContent>
		</Modal>
	);
});
