import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {
	IContest,
	IContestProp,
	ICreateBingoBoardPayload,
	IGameCard,
} from "data/types/entities";
import type {Empty} from "data/types/generics";
import {ContestStatus} from "data/enums";
import {last} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IGamePlayApiProvider} from "data/providers/api/gameplay.api.provider";
import {IAxiosApiErrorGeneral} from "data/types/api";

export interface IGamePlayStore {
	get selectedContest(): Empty<IContest>;

	get contests(): IContest[];

	get gameCard(): Empty<IGameCard>;

	get isSelectedContestLocked(): boolean;

	fetchContests(): Promise<void>;

	fetchGameCardForCurrentContest(squadId?: number): Promise<void>;

	getPropById(propId: Empty<number>): Empty<IContestProp>;

	getContestById(contestId: Empty<number>): Empty<IContest>;

	setSelectedContestById(contestId: Empty<number>): void;

	generateMultipleGameCards(payload: ICreateBingoBoardPayload[]): Promise<void>;

	updateBingoBoard(payload: ICreateBingoBoardPayload): Promise<void>;
}

@injectable()
export class GamePlayStore implements IGamePlayStore {
	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.GamePlayApiProvider) private _gamePlayApiProvider: IGamePlayApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private _gameCard: Empty<IGameCard>;

	get gameCard(): Empty<IGameCard> {
		return this._gameCard;
	}

	protected _contests: IContest[] = [];

	get contests(): IContest[] {
		return this._contests;
	}

	@observable protected _selectedContest: Empty<IContest>;

	get selectedContest(): Empty<IContest> {
		return this._selectedContest ?? this.nearestContest;
	}

	get isSelectedContestLocked(): boolean {
		if (!this.selectedContest) {
			return false;
		}
		return ![ContestStatus.Draft, ContestStatus.Open].includes(this.selectedContest.status);
	}

	protected get nearestContest(): Empty<IContest> {
		const scheduled = this._contests.find((e) => e.status === ContestStatus.Open);
		const live = this._contests.find((e) => e.status === ContestStatus.Live);
		const complete = this.contests.find((e) => e.status === ContestStatus.Complete);
		const nonDraft = this._contests.filter((e) => e.status !== ContestStatus.Draft);
		return live || scheduled || complete || last(nonDraft);
	}

	public async fetchContests(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.contests();

			const currentContest = data.find((e) => e.id === this._selectedContest?.id);
			runInAction(() => {
				this._contests = data.filter(
					(contest) => contest.status !== ContestStatus.Canceled
				);
				this._selectedContest = currentContest ?? this._selectedContest;
			});
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
		}
	}

	public async fetchGameCardForCurrentContest(squadId?: number) {
		if (!this.selectedContest) {
			return Promise.resolve();
		}
		try {
			const payload: ICreateBingoBoardPayload = {
				contestId: this.selectedContest.id,
				squadId,
			};
			const {data} = await this._gamePlayApiProvider.gameCard(payload);

			runInAction(() => {
				this._gameCard = data.success.gameCard;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public getPropById = (propId: Empty<number>) => {
		const contest = this._contests.find((e) => e.id === this.selectedContest?.id);
		if (contest) {
			return contest.props.find((prop) => prop.id === propId);
		}

		return this._contests
			.flatMap((contest) => contest.props)
			.find((prop) => prop.id === propId);
	};

	public getContestById(contestId: Empty<number>) {
		return this._contests.find((e) => e.id === contestId);
	}

	@action
	public setSelectedContestById(contestId: Empty<number>) {
		const contest = this.getContestById(contestId);
		if (!contest || this.selectedContest?.id === contestId) return;

		this._selectedContest = contest;
		this.clearGameCard();
	}

	@action
	public async generateMultipleGameCards(payload: ICreateBingoBoardPayload[]) {
		try {
			const {data} = await this._gamePlayApiProvider.multipleGameCards({gameCards: payload});

			const contestBySelected = data.success.gameCards.find(
				(e) => e.contestId === this.selectedContest?.id
			);
			if (contestBySelected) {
				runInAction(() => {
					this._gameCard = contestBySelected;
				});
			}
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
		}
	}

	@action
	public async updateBingoBoard(payload: ICreateBingoBoardPayload) {
		try {
			const {data} = await this._gamePlayApiProvider.updateGameCard(payload);

			runInAction(() => {
				this._gameCard = data.success.gameCard;
			});
			return Promise.resolve();
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
			return Promise.reject(e);
		}
	}

	protected clearGameCard(): void {
		runInAction(() => {
			this._gameCard = undefined;
		});
	}
}
