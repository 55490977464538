import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IMenuController} from "views/components/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import Logo from "assets/img/header-logo-white.png";
import {Exist} from "views/components/exist/exist.component";
import {MenuLogged} from "views/components/menu/menuLogged.component";
import {MenuNonLogged} from "views/components/menu/menuNonLogged.component";

const MenuBackdrop = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	background-color: transparent;
	transition: 0.5s background-color;

	&.open {
		z-index: 990;
		background-color: rgba(153, 153, 153, 0.6);
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 0 0 28px;
	border-bottom: 1px solid ${({theme}) => theme.color.components.menu.borderColor};

	img {
		margin-top: -3px;
		max-height: 60px;
		width: auto;
	}
`;

const MenuElement = styled.div`
	padding: 8px 20px 0 20px;
	z-index: 991;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100vh;
	transform: translateX(-100%);
	transition: 0.5s;
	width: 280px;
	background: ${({theme}) => theme.color.brand.primary};

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	&.open {
		transform: translateX(0);
	}
`;

export const MenuComponent: React.FC = observer(() => {
	const {isMenuOpen, isAuthorized} = useViewController<IMenuController>(Bindings.MenuController);
	const className = isMenuOpen ? "open" : "";
	return (
		<React.Fragment>
			<MenuElement className={className}>
				<LogoWrapper>
					<img src={Logo} alt="logo" />
				</LogoWrapper>
				<Exist when={isAuthorized}>
					<MenuLogged />
				</Exist>
				<Exist when={!isAuthorized}>
					<MenuNonLogged />
				</Exist>
			</MenuElement>
			<MenuBackdrop className={className} />
		</React.Fragment>
	);
});
