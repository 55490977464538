import React from "react";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {IContest} from "data/types/entities";
import {last} from "lodash";
import {Empty} from "data/types/generics";
import {ContestStatus} from "data/enums";

export interface IContestNavigationController extends ViewController {
	getIsContestActive: (contestId?: number) => string;
	getIsContestHasClaimModal: (contestId?: number) => string;
	getIsContestDisabled: (contestId: number) => boolean;
	setActiveContest: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	getSquadAbbr: (squadId: number) => string;

	get isFinalsDisabled(): boolean;

	get contests(): IContest[];

	get finalContest(): Empty<IContest>;
}

@injectable()
export class ContestNavigationController implements IContestNavigationController {
	constructor(
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	get isFinalsDisabled(): boolean {
		if (!this.finalContest) {
			return true;
		}
		return this.finalContest?.status === ContestStatus.Draft;
	}

	get contests(): IContest[] {
		return this._gameplayStore.contests.slice(0, 2);
	}

	get finalContest(): Empty<IContest> {
		if (this._gameplayStore.contests.length < 3) {
			return undefined;
		}
		return last(this._gameplayStore.contests.slice(0, 3));
	}

	public getIsContestActive = (contestId?: number) => {
		return this._gameplayStore.selectedContest?.id === contestId ? "active" : "";
	};

	public getIsContestHasClaimModal = (contestId?: number) => {
		return "";
	};

	public setActiveContest = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const contestId = Number(event.currentTarget.dataset.contest);
		if (!contestId) {
			return;
		}
		this._gameplayStore.setSelectedContestById(contestId);
	};

	public getIsContestDisabled = (contestId: number) => {
		const contest = this._gameplayStore.getContestById(contestId);
		if (!contest) {
			return true;
		}
		return contest.status === ContestStatus.Draft;
	};

	public getSquadAbbr = (squadId: number) => {
		return this._squadStore.getSquadById(squadId)?.abbreviation || "-";
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
