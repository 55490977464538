import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes} from "routes";
import {init, Integrations} from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {ThemeProvider} from "@mui/material";
import {theme} from "assets/theming/theme";
import styled from "@emotion/styled";
import {ModalError} from "views/components/modals/ModalError/modalError.component";
import {ModalSuccess} from "views/components/modals/ModalSuccess/modalSuccess.component";
import {HOCMain} from "views/components/HOC/HOCMain/HOCMain.component";
import {Preloader} from "views/components/preloader";
import {HOCSecretKeyComponent} from "views/components/HOC/HOCSecretKey/HOCSecretKey.component";
import {ModalPostSeason} from "views/components/modals/modalPostSeason/modalPostSeason.component";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "https://boxes.xfl.com"],
		ignoreErrors: ["Request failed with status code 401"],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

const AppWrapper = styled.div`
	background: radial-gradient(#999999 0%, #dbdbdb 25%);
	min-height: 100vh;
`;

const AppContainer = styled.div`
	min-height: 100vh;
	width: 100%;
	max-width: 420px;
	position: relative;
	margin: 0 auto;
	flex-flow: column wrap;
	-webkit-box-pack: unset;
	justify-content: unset;
	display: flex;
	flex: 1 1 0;
	align-items: flex-start;
	overflow: hidden;
	background: #ffffff;
`;

createRoot(root).render(
	<React.StrictMode>
		<InjectionProvider container={DIContainer}>
			<ThemeProvider theme={theme}>
				<BrowserRouter basename={BASE_URL}>
					<Suspense fallback={<Preloader fixed />}>
						<HOCSecretKeyComponent>
							<HOCMain>
								<AppWrapper>
									<AppContainer id="app-container">
										<RootRoutes />
										<ModalError />
										<ModalSuccess />
										<ModalPostSeason />
									</AppContainer>
								</AppWrapper>
							</HOCMain>
						</HOCSecretKeyComponent>
					</Suspense>
				</BrowserRouter>
			</ThemeProvider>
		</InjectionProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
