import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";

export interface IMenuController extends ViewController {
	closeMenu: () => void;
	openMenu: () => void;
	goToLogin: () => void;
	logout: () => void;

	get isMenuOpen(): boolean;

	get isAuthorized(): boolean;
}

@injectable()
export class MenuController implements IMenuController {
	constructor(
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore
	) {
		makeAutoObservable(this);
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public closeMenu = (): void => {
		this._menuStore.closeMenu();
	};

	public openMenu = (): void => {
		this._menuStore.toggleMenu();
	};

	public goToLogin = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
		this.closeMenu();
	};

	public logout = (): void => {
		void this._userStore.logout();
		this.closeMenu();
	};
}
