import styled from "@emotion/styled";
import pageBackground from "assets/img/pageBackground.svg";

export const Container = styled.div`
	padding: 20px;
	position: relative;
`;

export const PageLayout = styled.div`
	position: relative;
	background: url("${pageBackground}") #ffffff;
	width: 100%;
	min-height: 300px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 calc(50% + 52px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: url("${pageBackground}");
		width: 100%;
		height: 240px;
		background-size: contain;
		background-repeat: no-repeat;
	}
`;

export const AuthContainer = styled(Container)`
	padding: 68px 20px 150px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: 100vh;

	background: url("${pageBackground}") #ffffff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 bottom;

	overflow: auto;
	max-width: 420px;
	margin: 0 auto;

	&.full-screen {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	> img {
		height: 90px;
		width: auto;
		margin-bottom: 8px;
	}

	h1 {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		margin-bottom: 28px;
	}

	p.desc {
		margin-bottom: 18px;
		text-align: center;
	}

	a.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const RegisterLink = styled.span`
	display: block;
	text-align: center;
	margin-top: 16px;
	margin-bottom: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.01em;
	text-transform: capitalize;

	a,
	button {
		text-decoration: underline;
		text-transform: capitalize;
		padding: 0;
	}
`;

export const SquadImageWrapper = styled.div`
	width: 44px;
	height: auto;

	img,
	.preloader {
		width: 44px;
		height: auto;
		max-width: 44px;
	}
`;

export const HelpContent = styled.div`
	font-size: 100%;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: currentColor;
		margin-bottom: 0.75em;
	}

	h4 {
		font-size: 1.125em;
		line-height: 1.75em;
	}

	h3 {
		font-size: 1.25em;
		line-height: 2em;
		margin-bottom: 0.625em;
	}

	li {
		line-height: 18px;
	}

	p,
	table,
	ul,
	ol {
		font-weight: 500;
		font-size: 15px;
		line-height: 140%;
		letter-spacing: 0.01em;
	}

	ol,
	ul {
		margin-left: 1em;
	}

	ul {
		list-style-type: circle;
	}

	ol {
		list-style-type: decimal;
	}

	i {
		font-style: italic;
	}

	b,
	strong {
		font-weight: bold;
	}

	a {
		color: var(--primaryColor);
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;

		@media (max-width: 639px) {
			width: 100%;
		}

		td {
			text-align: right;
			padding: 10px;
			border: 1px solid #eaeaea;
			background-color: #ffffff;

			&:first-of-type {
				text-align: left;
				text-transform: uppercase;
				font-weight: 700;
				border: none;
				background: none;
			}
		}
	}

	img {
		max-width: 100%;
	}

	*:last-child {
		margin-bottom: 0;
	}
`;

export const MenuBlock = styled.div`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	width: 100%;

	a,
	button {
		font-style: normal;
		text-decoration: none;
		font-weight: 500;
		font-size: 15px;
		line-height: 118%;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.color.brand.white};
		padding: 0;

		&:hover {
			color: ${({theme}) => theme.color.brand.white};
			text-decoration: underline;
		}
	}

	.sub-link {
		padding-left: 12px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
	}

	&.bb {
		border-bottom: 1px solid ${({theme}) => theme.color.components.menu.borderColor};
	}
`;

export const GameCardWrapper = styled.div`
	width: 110px;
	height: 110px;
	background: ${({theme, color}) => color ?? theme.color.components.propCard.background};
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 8px;

	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.01em;
	position: relative;

	span.text {
		z-index: 2;
	}

	&.no-squad {
		background: ${({theme}) => theme.color.brand.primary};
		color: ${({theme}) => theme.color.brand.white};
		span {
			font-size: 14px;
		}
	}

	img {
		width: 100%;
		height: auto;
		max-height: 100%;
	}

	&:after {
		content: "";
		position: absolute;
		top: 15px;
		right: 15px;
		bottom: 15px;
		left: 15px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background: transparent;
		pointer-events: none;
	}

	&.correct {
		background: ${({theme}) => theme.color.components.propCard.correctBackground};

		&:after {
			background: ${({theme}) => theme.color.components.propCard.correctCircleBackground};
		}
	}

	&.incorrect {
		background: ${({theme}) => theme.color.components.propCard.incorrectBackground};
		color: ${({theme}) => theme.color.brand.primary};
		span {
			opacity: 0.3;
		}
	}
`;

export const LabelChip = styled.div`
	padding: 4px 8px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;

	font-weight: 500;
	font-size: 9px;
	line-height: 118%;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.color.brand.white};

	&.open {
		background: ${({theme}) => theme.color.components.chip.open.background};
		color: ${({theme}) => theme.color.components.chip.open.color};
	}

	&.live {
		background: ${({theme}) => theme.color.components.chip.live.background};
		color: ${({theme}) => theme.color.components.chip.live.color};
	}

	&.complete {
		background: ${({theme}) => theme.color.components.chip.complete.background};
		color: ${({theme}) => theme.color.components.chip.complete.color};
	}
`;
