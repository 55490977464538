import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";

export interface IHeaderController extends ViewController {
	toggleMenu: () => void;
	goToLogin: () => void;

	get isMenuOpen(): boolean;
	get isAuthorized(): boolean;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	@action
	public toggleMenu = (): void => {
		this._menuStore.toggleMenu();
	};

	public goToLogin = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}
}
