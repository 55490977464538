import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useLocation} from "react-router";
import {useViewController} from "data/services/locator";
import {IMenuController} from "views/components/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import {MenuBlock} from "views/components/common/layouts";
import {NavLink} from "react-router-dom";
import {Button, Typography} from "@mui/material";

export const MenuLogged: React.FC = observer(() => {
	const location = useLocation();
	const {logout, closeMenu} = useViewController<IMenuController>(Bindings.MenuController);

	useEffect(() => {
		closeMenu();
	}, [location, closeMenu]);
	return (
		<React.Fragment>
			<MenuBlock className="bb">
				<NavLink to="/">
					<Typography variant="body1">Home</Typography>
				</NavLink>
			</MenuBlock>
			<MenuBlock className="bb">
				<NavLink to="/help">
					<Typography variant="body1">Help</Typography>
				</NavLink>
				<NavLink to="/my-account">
					<Typography variant="body1">My Account</Typography>
				</NavLink>
			</MenuBlock>
			<MenuBlock>
				<Button color="link" onClick={logout}>
					<Typography variant="body1">LOG OUT</Typography>
				</Button>
			</MenuBlock>
		</React.Fragment>
	);
});
