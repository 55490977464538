import {action, makeAutoObservable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";
import type {IModalEntity, IModalMessage, TModalArguments} from "data/types/modals";
import {IAxiosApiError, IAxiosApiErrorGeneral} from "data/types/api";
import {AxiosError} from "axios";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {Nullable} from "data/types/generics";

export interface IModalsStore {
	showModal<T>(...args: TModalArguments<T>): void;

	hideModal(modal?: ModalType): void;

	showError(args: IModalMessage): void;

	showAxiosError(args: IAxiosApiErrorGeneral): void;

	isModalOpen(modal: ModalType): boolean;

	getModalContent(modal: ModalType): IModalMessage | unknown | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	protected _visibleModals: IModalEntity[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	getModalContent(modal: ModalType): Nullable<IModalMessage | unknown> {
		return this._visibleModals.find((e) => e.modal === modal)?.content;
	}

	protected get modals(): IModalEntity[] {
		return this._visibleModals;
	}

	@action
	public hideModal(modal?: ModalType): void {
		if (!this.isModalType(modal)) {
			this._visibleModals = [];
			return;
		}
		this._visibleModals = this._visibleModals.filter((e) => e.modal !== modal);
	}

	@action
	public showModal<T>(...args: TModalArguments<T>): void {
		const [modalType, content = null] = args;

		const modalData = {
			modal: modalType,
			content,
		};

		this._visibleModals.push(modalData);
	}

	public showError(args: IModalMessage): void {
		console.log(args);
		this.showModal(ModalType.ERROR, args);
	}

	public showAxiosError(args: AxiosError<IAxiosApiError, unknown> | undefined): void {
		if (!args) {
			return;
		}

		this.showError({
			title: "Error",
			message: getErrorMessageFromAxiosResponse(args),
		});
	}

	public isModalOpen(modal: ModalType) {
		if (!this.isModalType(modal)) {
			return false;
		}
		return this._visibleModals.some((e) => e.modal === modal);
	}

	protected isModalType(modal: null | undefined | ModalType): boolean {
		return modal !== null && modal !== undefined;
	}
}
