import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {Empty} from "data/types/generics";
import type {ISquad, ISquadsStore, SquadLogos} from "data/stores/squads/squads.store";
import {getImageFromBackend} from "data/utils/helpers";
import {ViewController} from "data/types/structure";

interface IParams {
	squadId: number | undefined;
	type: Empty<SquadLogos>;
}

export interface ISquadImageController extends ViewController<IParams> {
	onLoaded: () => void;

	onError: () => void;

	passSquadId: (squadId: number) => void;

	passType: (type: SquadLogos) => void;

	get squadImage(): string;

	get squad(): Empty<ISquad>;

	get isLoading(): boolean;

	get className(): string;
}

@injectable()
export class SquadImageController implements ISquadImageController {
	@observable private _squadId: number | undefined;
	@observable private _type: Empty<SquadLogos>;

	constructor(@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore) {
		makeAutoObservable(this);
	}

	@observable private _className: string = "";

	get className(): string {
		return this._className;
	}

	@observable private _isLoading: boolean = true;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get squadImage(): string {
		if (!this.squad) {
			return "";
		}
		switch (this._type) {
			case "logo":
				return getImageFromBackend(this.squad.logo);
			case "blackWhiteLogo":
				return getImageFromBackend(this.squad.blackWhiteLogo);
			case "logoWhiteBackground":
				return getImageFromBackend(this.squad.logoWhiteBackground);
			default:
				return getImageFromBackend(this.squad.logo);
		}
	}

	public get squad(): Empty<ISquad> {
		return this._squadStore.getSquadById(this._squadId);
	}

	@action
	public onLoaded = () => {
		this._className = "";
		this._isLoading = false;
	};

	@action
	public onError = () => {
		this._className += " error";
		this._isLoading = false;
	};

	@action
	public passSquadId = (squadId: number) => {
		this._squadId = squadId;
	};

	@action
	public passType = (type: SquadLogos) => {
		this._type = type;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._squadId = param.squadId;
		this._type = param.type;
	}
}
