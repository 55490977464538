import React from "react";
import {useViewController} from "data/services/locator";
import {IModalController} from "views/components/modals/modal.controller";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {IModalMessage} from "data/types/modals";
import {Button, Modal, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {ModalCloseButton, ModalContent, ModalIcon, ModalTitle} from "views/components/common/modal";
import {Exist} from "views/components/exist/exist.component";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {getDashedValue} from "data/utils/helpers";

export const ModalSuccess: React.FC = observer(() => {
	const {isModalOpen, close, modalData} = useViewController<IModalController>(
		Bindings.ModalController,
		{modal: ModalType.SUCCESS}
	);
	const isOpen = isModalOpen(ModalType.SUCCESS);

	const data = modalData as IModalMessage;
	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton color="modalClose" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<ModalIcon className="success">
					<CheckCircleIcon />
				</ModalIcon>

				<Exist when={Boolean(data?.title)}>
					<ModalTitle>{getDashedValue(data?.title)}</ModalTitle>
				</Exist>
				<Typography variant="body1">{getDashedValue(data?.message)}</Typography>

				<Button fullWidth onClick={close}>
					{data?.buttonText || "Close"}
				</Button>
			</ModalContent>
		</Modal>
	);
});
