import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {NavLink} from "react-router-dom";
import {MenuBlock} from "views/components/common/layouts";
import {Button, Typography} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IMenuController} from "views/components/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import {useLocation} from "react-router";

export const MenuNonLogged: React.FC = observer(() => {
	const location = useLocation();
	const {goToLogin, closeMenu} = useViewController<IMenuController>(Bindings.MenuController);

	useEffect(() => {
		closeMenu();
	}, [location, closeMenu]);

	return (
		<React.Fragment>
			<MenuBlock className="bb">
				<NavLink to="/">
					<Typography variant="body1">Home</Typography>
				</NavLink>
				<Button color="link" onClick={goToLogin}>
					<Typography variant="body1">Log In & Register</Typography>
				</Button>
			</MenuBlock>
			<MenuBlock>
				<NavLink to="/help">
					<Typography variant="body1">Help</Typography>
				</NavLink>
			</MenuBlock>
		</React.Fragment>
	);
});
