import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {IAccountForm, IUpdateUserPayload, ValidationScheme} from "data/types/entities";
import {UsernameValidator} from "data/utils/validators/Username.validator";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import React from "react";
import {getErrorMessageFromAxiosResponse, serializeForm} from "data/utils/helpers";
import type {IAxiosApiErrorGeneral} from "data/types/api";
import {isEqual} from "lodash";

export interface IMyAccountController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IAccountForm>) => void;
	handleFormChange: (event: React.ChangeEvent<IAccountForm>) => void;

	get user(): IUser | undefined;

	get isLoading(): boolean;

	get formErrors(): IFormValidator["formErrors"];

	get isSubmitDisabled(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	private readonly _validationScheme: ValidationScheme = {
		username: [new UsernameValidator()],
	};

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	protected _isSubmitDisabled = true;

	get isSubmitDisabled(): boolean {
		return this.isLoading || this._isSubmitDisabled;
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get user() {
		return this._userStore.user;
	}

	get formErrors(): IFormValidator["formErrors"] {
		return this._formValidator.formErrors;
	}

	dispose(): void {
		return;
	}

	init(props: void): void {
		return;
	}

	@action
	public handleFormChange = (event: React.ChangeEvent<IAccountForm>) => {
		const data = serializeForm<IUpdateUserPayload>(new FormData(event.currentTarget));

		const userData: IUpdateUserPayload = {
			username: String(this.user?.username),
			firstName: String(this.user?.firstName),
			lastName: String(this.user?.lastName),
		};

		this._isSubmitDisabled = isEqual(data, userData);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IAccountForm>): void => {
		event.preventDefault();
		if (!event.currentTarget) {
			return;
		}
		this.clearErrors();
		this._formValidator.validate(event.currentTarget);

		if (!this._formValidator.isValid) {
			return;
		}

		const payload = serializeForm<IUpdateUserPayload>(new FormData(event.currentTarget));

		this._isLoading = true;
		this._userStore
			.updateUser(payload)
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	protected onSuccess() {
		this._isSubmitDisabled = true;
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected onFailure(error: IAxiosApiErrorGeneral) {
		const errorMessage = getErrorMessageFromAxiosResponse(error);
		if (errorMessage.includes("not contain profanities")) {
			this._formValidator.setError("username", errorMessage);
			return;
		}

		this._modalsStore.showAxiosError(error);
	}

	protected clearErrors(): void {
		this._formValidator.clearErrors();
	}
}
