import {ViewController} from "data/types/structure";
import {injectable} from "inversify";

export interface ISecretGateController extends ViewController {
	handleSecretClick: () => void;
}

@injectable()
export class SecretGateController implements ISecretGateController {
	private static _count = 0;

	static get IS_SECRET_PASSED() {
		const IS_SECRET_ENABLED = Boolean(
			JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
		);

		if (IS_SECRET_ENABLED) {
			return Boolean(JSON.parse(sessionStorage.getItem("isSecretPassed") || "false"));
		}

		return true;
	}

	handleSecretClick = () => {
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		SecretGateController._count += 1;

		if (SecretGateController._count >= 10) {
			sessionStorage.setItem("isSecretPassed", "true");
			window.location.reload();
		}
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
