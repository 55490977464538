import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {MenuButton} from "views/components/menuButton/menuButton.component";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "./header.controller";
import {Bindings} from "data/constants/bindings";
import LoginIcon from "@mui/icons-material/Login";
import {Button} from "@mui/material";
import {NavLink} from "react-router-dom";
import Logo from "assets/img/header-logo.png";
import {Exist} from "views/components/exist/exist.component";
import {MenuComponent} from "views/components/menu/menu.component";

const HeaderElement = styled.header`
	height: 54px;
	width: 100%;
	background: ${({theme}) => theme.color.brand.white};
	border-bottom: 2px solid ${({theme}) => theme.color.brand.primary};
	padding: 6px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	> button {
		width: 32px;
		height: 32px;
	}
`;

const LeftBlock = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	margin-right: auto;

	img.logo {
		height: 100%;
		max-height: 60px;
		margin-top: 4px;
		width: auto;
	}
`;

export const Header: React.FC = observer(() => {
	const {isMenuOpen, isAuthorized, toggleMenu, goToLogin} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);

	return (
		<HeaderElement>
			<LeftBlock>
				<MenuButton isOpen={isMenuOpen} onClick={toggleMenu} />
				<NavLink to="/">
					<img className="logo" src={Logo} alt="XFL Bingo" />
				</NavLink>
			</LeftBlock>

			<Exist when={!isAuthorized}>
				<Button className="login" color="adornment" onClick={goToLogin}>
					<LoginIcon />
				</Button>
			</Exist>
			<MenuComponent />
		</HeaderElement>
	);
});
