import React from "react";
import {observer} from "mobx-react";
import {ModalCloseButton, ModalContent, ModalIcon, ModalTitle} from "views/components/common/modal";
import {Button, Modal, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IModalForgotPasswordController} from "views/components/modals/modalForgotPassword/modalForgotPassword.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

export const ModalForgotPassword: React.FC = observer(() => {
	const {handleFormSubmit, handleFormChange, close, isOpen, isSubmitDisabled, error} =
		useViewController<IModalForgotPasswordController>(Bindings.ModalForgotPasswordController);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseButton color="modalClose" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>

				<ModalIcon className="error">
					<ErrorIcon />
				</ModalIcon>

				<ModalTitle>Forgot Password</ModalTitle>
				<Typography variant="body1">
					Please enter the email address you’re currently registered with.
				</Typography>

				<Exist when={Boolean(error)}>
					<Typography variant="error">{error}</Typography>
				</Exist>

				<Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
					<TextField
						fullWidth
						name="email"
						type="email"
						label="Email Address"
						placeholder="Email Address"
						required
					/>

					<Button fullWidth type="submit" disabled={isSubmitDisabled}>
						Submit
					</Button>
				</Form>
			</ModalContent>
		</Modal>
	);
});
