import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IAuthBlockController extends ViewController {
	login: () => void;
	register: () => void;

	get isAuthorized(): boolean;
}

@injectable()
export class AuthBlockController implements IAuthBlockController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public login = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	public register = () => {
		this._modalsStore.showModal(ModalType.PRE_REGISTRATION);
	};
}
