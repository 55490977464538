import {action, makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {Empty} from "data/types/generics";
import {ModalType} from "data/enums";
import type {IStaticContentStore} from "data/stores/static_content/static_content.store";

export interface IHOCMainController extends ViewController {
	get isLoading(): boolean;
	get hasSettings(): boolean;
}

@injectable()
export class HOCMainController implements IHOCMainController {
	constructor(
		@inject(Bindings.SquadsStore) private _squadStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore,
		@inject(Bindings.StaticContentStore) private _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	private _isLoading: boolean = true;

	get isLoading(): boolean {
		return this._isLoading;
	}

	protected get user(): Empty<IUser> {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchUser();
		this.checkTerms();
		this.fetchGameInfo();
		this.fetchSettings();
	}

	protected fetchUser() {
		this._userStore.fetchUser().finally(this.onFinally.bind(this));
	}

	protected fetchSettings() {
		void this._staticContentStore.fetchSettings();
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	protected checkTerms() {
		reaction(
			() => this.user,
			() => {
				if (!this.user) {
					return;
				}
				const isPreRegisterModal = this._modalsStore.isModalOpen(
					ModalType.PRE_REGISTRATION
				);
				if (!this.user.isPlayed && !isPreRegisterModal) {
					this._modalsStore.showModal(ModalType.TERMS);
				}

				this.fetchGameInfo();
				void this._gameBarStore.fetchGameBar();
			}
		);
	}

	protected fetchGameInfo(): void {
		void this._squadStore.fetchSquads();
		void this._gameplayStore.fetchContests();
	}

	get hasSettings(): boolean {
		return Boolean(this._staticContentStore.settings);
	}
}
