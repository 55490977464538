import {createTheme} from "@mui/material/styles";
import {colorsTheme} from "assets/theming/palette.theme";
import * as React from "react";

const fontFamily = ["FK Grotesk Neue", "sans-serif"].join(",");

const body1Styles: React.CSSProperties = {
	fontFamily,
	fontWeight: 600,
	fontSize: "15px",
	lineHeight: " 118%",
	letterSpacing: "0.01em",
};

export const typographyTheme = createTheme({
	typography: {
		allVariants: {
			fontFamily,
		},
		body1: {
			...body1Styles,
		},
		body1Light: {
			...body1Styles,
			fontWeight: 500,
		},
		h3: {
			fontFamily,
			fontWeight: 700,
			fontSize: "20px",
			lineHeight: "118%",
			textAlign: "center",
			letterSpacing: "0.01em",
			textTransform: "uppercase",
			color: colorsTheme.palette.primary.main,
		},
		caption: {
			fontFamily,
			fontWeight: 500,
			fontSize: "11px",
			lineHeight: "118%",
			letterSpacing: "0.01em",
			textTransform: "uppercase",
			color: colorsTheme.color.brand.white,
		},
		button: {
			fontSize: "16px",
			textTransform: "uppercase",
			fontWeight: "500",
		},
		error: {
			...body1Styles,
			textAlign: "center",
			color: colorsTheme.color.brand.error,
		},
	},
});
