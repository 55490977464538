import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IModalLoginController extends ViewController {
	get isOpen(): boolean;

	close: () => void;
}

@injectable()
export class ModalLoginController implements IModalLoginController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.LOGIN);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};
}
