import {createTheme} from "@mui/material/styles";

const commonColors = {
	primary: "#121212",
	primaryAction: "#303030",
};

export const colorsTheme = {
	palette: {
		primary: {
			main: commonColors.primary,
			dark: commonColors.primaryAction,
		},
		adornment: {
			main: commonColors.primary,
		},
		modalClose: {
			main: commonColors.primary,
		},
		link: {
			main: commonColors.primary,
		},
		contestNavigation: {
			main: commonColors.primary,
		},
	},
	color: {
		brand: {
			primary: commonColors.primary,
			white: "#FFFFFF",
			lightGrey: "#DBDBDB",
			error: "#C20000",
			success: "#1AC971",
			info: "#2097F3",
		},
		text: {
			primary: "#000000",
		},
		components: {
			button: {
				primary: {
					color: "#ffffff",
					background: commonColors.primary,
					borderColor: commonColors.primary,

					disabledColor: "#ffffff",
					disabledBackground: "#B7B7B7",
					disabledBorderColor: "#B7B7B7",

					hoverColor: "#fff",
					hoverBackground: commonColors.primaryAction,
					hoverBorderColor: commonColors.primaryAction,
				},
				secondary: {
					color: commonColors.primary,
					background: "#FFFFFF",
					borderColor: commonColors.primary,

					disabledColor: "#AEAEAE",
					disabledBackground: "transparent",
					disabledBorderColor: "#AEAEAE",

					hoverColor: commonColors.primaryAction,
					hoverBackground: "transparent",
					hoverBorderColor: commonColors.primaryAction,
				},
				pure: {
					color: commonColors.primary,
					background: "transparent",
					borderColor: "transparent",

					disabledColor: "#B7B7B7",
					disabledBackground: "transparent",
					disabledBorderColor: "transparent",

					hoverColor: commonColors.primaryAction,
					hoverBackground: "transparent",
					hoverBorderColor: "transparent",
				},
				contestNavigation: {
					color: "#FFFFFF",
					background: "transparent",
					borderColor: "transparent",

					disabledColor: "#888888",
					disabledBackground: "transparent",
					disabledBorderColor: "transparent",

					hoverColor: commonColors.primaryAction,
					hoverBackground: "#F1F1F1",
					hoverBorderColor: "#F1F1F1",
				},
			},
			input: {
				defaultColor: "rgb(163, 163, 163)",
				focusedColor: commonColors.primary,
				disabledColor: "#D9D9D9",
				error: "#C20000",
				helperColor: "rgba(0, 0, 0, 0.6)",
			},
			authBlock: {
				background: "#FFFFFF",
				borderColor: "#DBDBDB",
			},
			menu: {
				borderColor: "rgb(51, 51, 51)",
			},
			propCard: {
				background: "#f1f1f1",
				correctBackground:
					" linear-gradient(0deg, rgba(24, 201, 113, 0.1), rgba(24, 201, 113, 0.1)), #FFFFFF",
				correctCircleBackground: "#AAEBCB",
				incorrectBackground: "#F1F1F1",
			},
			chip: {
				open: {
					background: "#2196F3",
					color: "#FFFFFF",
				},
				live: {
					background: "#29CC6A",
					color: "#FFFFFF",
				},
				complete: {
					background: "#333333",
					color: "#FFFFFF",
				},
			},
		},
	},
};

export const paletteTheme = createTheme(colorsTheme);
