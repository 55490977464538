export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "/";
export const POSTCODE_COUNTRIES = ["USA"];
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "media/image/";

export const SECOND = 1000;
export const NO_BINGO_BOARD_GENERATED_CODE = 422;
export const LIVE_SCORING_UPDATE_TIMING = SECOND * 60;
export const SQUAD_PROP_POSITION = 5;
export const IS_SECRET_ENABLED = JSON.parse(
	process.env.REACT_APP_IS_SECRET_ENABLED || "false"
) as boolean;
