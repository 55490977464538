import React from "react";
import {Button, Modal, Typography} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IModalController} from "views/components/modals/modal.controller";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {getDashedValue} from "data/utils/helpers";
import {IModalMessage} from "data/types/modals";
import {Exist} from "views/components/exist/exist.component";
import {ModalCloseButton, ModalTitle, ModalContent, ModalIcon} from "views/components/common/modal";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

export const ModalError: React.FC = observer(() => {
	const {isModalOpen, close, modalData} = useViewController<IModalController>(
		Bindings.ModalController,
		{modal: ModalType.ERROR}
	);
	const isOpen = isModalOpen(ModalType.ERROR);

	const data = modalData as IModalMessage;

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton color="modalClose" onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<ModalIcon className="error">
					<ErrorIcon />
				</ModalIcon>

				<Exist when={Boolean(data?.title)}>
					<ModalTitle>{data?.title}</ModalTitle>
				</Exist>
				<Exist when={!data?.title}>
					<ModalTitle>Error</ModalTitle>
				</Exist>
				<Typography variant="body1">{getDashedValue(data?.message)}</Typography>

				<Button fullWidth onClick={close}>
					Close
				</Button>
			</ModalContent>
		</Modal>
	);
});
