import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import {ModalType} from "data/enums";
import {Empty} from "data/types/generics";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";

export interface IModalGotBingoController extends ViewController {
	close: () => void;

	goToBoard: () => void;

	get isLoading(): boolean;

	get isOpen(): boolean;
}

@injectable()
export class ModalGotBingoController implements IModalGotBingoController {
	constructor(
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore
	) {
		makeAutoObservable(this);
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.isModalOpen(ModalType.GOT_BINGO);
	}

	protected get liveContestId(): Empty<number> {
		return this._gameBarStore.liveGameBar?.contestId;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._gameBarStore
			.setBingoModalViewed(this.liveContestId)
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	public goToBoard = () => {
		this._gameplayStore.setSelectedContestById(this.liveContestId);
		this.close();
	};

	@action
	protected onSuccess() {
		this._modalsStore.hideModal(ModalType.GOT_BINGO);
		void this._gameBarStore.fetchGameBar();
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		this._modalsStore.showAxiosError(error);
		this.onSuccess();
	}
}
