import {ThemeOptions} from "@mui/material/styles/createTheme";

export const checkboxTheme: ThemeOptions = {
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						".MuiSvgIcon-root path": {
							color: "#121212",
						},
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					width: "100%",
					textAlign: "left",
				},
			},
		},
	},
};
