import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {GamePlayController, IGamePlayController} from "views/pages/gameplay/gameplay.controller";
import {
	IModalRegisterController,
	ModalRegisterController,
} from "views/components/modals/modalRegister/modalRegister.controller";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	FormRegisterController,
	IFormRegisterController,
} from "views/components/formRegister/formRegister.controller";
import {FormValidator, IFormValidator} from "data/utils/validators/FormValidator";
import {
	AuthBlockController,
	IAuthBlockController,
} from "views/components/authBlock/authBlock.controller";
import {
	FormLoginController,
	IFormLoginController,
} from "views/components/formLogin/formLogin.controller";
import {
	IModalLoginController,
	ModalLoginController,
} from "views/components/modals/modalLogin/modalLogin.controller";
import {
	IModalForgotPasswordController,
	ModalForgotPasswordController,
} from "views/components/modals/modalForgotPassword/modalForgotPassword.controller";
import {IModalController, ModalController} from "views/components/modals/modal.controller";
import {
	IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	HOCMainController,
	IHOCMainController,
} from "views/components/HOC/HOCMain/HOCMain.controller";
import {
	IModalTermsController,
	ModalTermsController,
} from "views/components/modals/modalTerms/modalTerms.controller";
import {
	IModalPreRegistrationController,
	ModalPreRegistrationController,
} from "views/components/modals/modalPreRegistration/modalPreRegistration.controller";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {IMenuController, MenuController} from "views/components/menu/menu.controller";
import {GamePlayStore, IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import {
	GamePlayCardController,
	IGamePlayCardController,
} from "views/components/gameplayCard/gameplayCard.controller";
import {
	CardPropController,
	ICardPropController,
} from "views/components/cardProp/cardProp.controller";
import {
	ISquadImageController,
	SquadImageController,
} from "views/components/squadImage/squadImage.controller";
import {GamePlayApiProvider, IGamePlayApiProvider} from "data/providers/api/gameplay.api.provider";
import {
	ContestLabelController,
	IContestLabelController,
} from "views/components/contestLabel/contestLabel.controller";
import {
	ContestNavigationController,
	IContestNavigationController,
} from "views/components/contestNavigation/contestNavigation.controller";
import {
	ChangePasswordController,
	IChangePasswordController,
} from "views/components/changePassword/changePassword.controller";
import {
	IModalCreateBingoBoardController,
	ModalCreateBingoBoardController,
} from "views/components/modals/modalCreateBingoBoard/modalCreateBingoBoard.controller";
import {
	IModalClaimBingoController,
	ModalClaimBingoController,
} from "views/components/modals/modalClaimBingo/modalClaimBingo.controller";
import {
	IModalWelcomeController,
	ModalWelcomeController,
} from "views/components/modals/modalWelcome/modalWelcome.controller";
import {
	CardPropSquadController,
	ICardPropSquadController,
} from "views/components/cardPropSquad/cardPropSquad.controller";
import {
	HOCCardsHighlightController,
	IHOCCardsHighlightController,
} from "views/components/HOC/HOCCardsHighlight/HOCCardsHighlight.controller";
import {
	EntriesLabelController,
	IEntriesLabelController,
} from "views/components/entriesLabel/entriesLabel.controller";
import {GameBarStore, IGameBarStore} from "data/stores/gamebar/gamebar.store";
import {
	ContactUsController,
	IContactUsController,
} from "views/components/contactUs/contactUs.controller";
import {
	ISocialSharingController,
	SocialSharingController,
} from "views/components/socialSharing/socialSharing.controller";
import {
	IModalGotBingoController,
	ModalGotBingoController,
} from "views/components/modals/modalGotBingo/modalGotBingo.controller";
import {ILiveScoringStore, LiveScoringStore} from "data/stores/liveScoring/livescoring.store";
import {
	CreateBingoBoardButtonsController,
	ICreateBingoBoardButtonsController,
} from "views/components/createBingoBoardButtons/createBingoBoardButtons.controller";
import {
	IModalCreateSingleBingoBoardController,
	ModalCreateSingleBingoBoardController,
} from "views/components/modals/modalCreateSingleBingoBoard/modalCreateSingleBingoBoard.controller";
import {
	HOCSecretKeyController,
	IHOCSecretKeyController,
} from "views/components/HOC/HOCSecretKey/HOCSecretKey.controller";
import {
	IModalPostSeasonController,
	ModalPostSeasonController,
} from "views/components/modals/modalPostSeason/modalPostSeason.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IGamePlayApiProvider>(Bindings.GamePlayApiProvider).to(GamePlayApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IGamePlayStore>(Bindings.GamePlayStore).to(GamePlayStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<ILiveScoringStore>(Bindings.LiveScoringStore).to(LiveScoringStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IGamePlayController>(Bindings.GamePlayController).to(GamePlayController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<IFormValidator>(Bindings.FormValidator).to(FormValidator);
	bind<IAuthBlockController>(Bindings.AuthBlockController).to(AuthBlockController);
	bind<IModalRegisterController>(Bindings.ModalRegisterController).to(ModalRegisterController);
	bind<IFormRegisterController>(Bindings.FormRegisterController).to(FormRegisterController);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<IModalLoginController>(Bindings.ModalLoginController).to(ModalLoginController);
	bind<IModalForgotPasswordController>(Bindings.ModalForgotPasswordController).to(
		ModalForgotPasswordController
	);
	bind<IModalController>(Bindings.ModalController).to(ModalController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IHOCMainController>(Bindings.HOCMainController).to(HOCMainController);
	bind<IModalTermsController>(Bindings.ModalTermsController).to(ModalTermsController);
	bind<IModalPreRegistrationController>(Bindings.ModalPreRegistrationController).to(
		ModalPreRegistrationController
	);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IGamePlayCardController>(Bindings.GamePlayCardController).to(GamePlayCardController);
	bind<ICardPropController>(Bindings.CardPropController).to(CardPropController);
	bind<ISquadImageController>(Bindings.SquadImageController).to(SquadImageController);
	bind<IContestLabelController>(Bindings.ContestLabelController).to(ContestLabelController);
	bind<IContestNavigationController>(Bindings.ContestNavigationController).to(
		ContestNavigationController
	);
	bind<IChangePasswordController>(Bindings.ChangePasswordController).to(ChangePasswordController);
	bind<IModalCreateBingoBoardController>(Bindings.ModalCreateBingoBoardController).to(
		ModalCreateBingoBoardController
	);
	bind<IModalClaimBingoController>(Bindings.ModalClaimBingoController).to(
		ModalClaimBingoController
	);
	bind<IModalWelcomeController>(Bindings.ModalWelcomeController).to(ModalWelcomeController);
	bind<ICardPropSquadController>(Bindings.CardPropSquadController).to(CardPropSquadController);
	bind<IHOCCardsHighlightController>(Bindings.HOCCardsHighlightController).to(
		HOCCardsHighlightController
	);
	bind<ISocialSharingController>(Bindings.SocialSharingController).to(SocialSharingController);
	bind<IEntriesLabelController>(Bindings.EntriesLabelController).to(EntriesLabelController);
	bind<IModalGotBingoController>(Bindings.ModalGotBingoController).to(ModalGotBingoController);
	bind<ICreateBingoBoardButtonsController>(Bindings.CreateBingoBoardButtonsController).to(
		CreateBingoBoardButtonsController
	);
	bind<IModalCreateSingleBingoBoardController>(Bindings.ModalCreateSingleBingoBoardController).to(
		ModalCreateSingleBingoBoardController
	);
	bind<IHOCSecretKeyController>(Bindings.HOCSecretKeyController).to(HOCSecretKeyController);
	bind<IModalPostSeasonController>(Bindings.ModalPostSeasonController).to(
		ModalPostSeasonController
	);
});
