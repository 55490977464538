export const Bindings = {
	// Providers
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	GamePlayApiProvider: Symbol("GamePlayApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),

	// Stores
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	ModalsStore: Symbol("ModalsStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	MenuStore: Symbol("MenuStore"),
	GamePlayStore: Symbol("GamePlayStore"),
	GameBarStore: Symbol("GameBarStore"),
	LiveScoringStore: Symbol("LiveScoringStore"),

	// Controllers
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	GamePlayController: Symbol("GamePlayController"),
	FormRegisterController: Symbol("FormRegisterController"),
	AuthBlockController: Symbol("AuthBlockController"),
	FormLoginController: Symbol("FormLoginController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	HOCMainController: Symbol("HOCMainController"),
	HeaderController: Symbol("HeaderController"),
	MenuController: Symbol("MenuController"),
	GamePlayCardController: Symbol("GamePlayCardController"),
	CardPropController: Symbol("CardPropController"),
	SquadImageController: Symbol("SquadImageController"),
	CardSquadController: Symbol("CardSquadController"),
	ContestLabelController: Symbol("ContestLabelController"),
	ContestNavigationController: Symbol("ContestNavigationController"),
	ChangePasswordController: Symbol("ChangePasswordController"),
	CardPropSquadController: Symbol("CardPropSquadController"),
	HOCCardsHighlightController: Symbol("HOCCardsHighlightController"),
	EntriesLabelController: Symbol("EntriesLabelController"),
	ContactUsController: Symbol("ContactUsController"),
	SocialSharingController: Symbol("SocialSharingController"),

	// Modals
	ModalCreateBingoBoardController: Symbol("ModalCreateBingoBoardController"),
	ModalClaimBingoController: Symbol("ModalClaimBingoController"),
	ModalRegisterController: Symbol("ModalRegisterController"),
	ModalLoginController: Symbol("ModalLoginController"),
	ModalForgotPasswordController: Symbol("ModalForgotPasswordController"),
	ModalController: Symbol("ModalController"),
	ModalTermsController: Symbol("ModalTermsController"),
	ModalPreRegistrationController: Symbol("ModalPreRegistrationController"),
	ModalWelcomeController: Symbol("ModalWelcomeController"),
	ModalGotBingoController: Symbol("ModalGotBingoController"),
	CreateBingoBoardButtonsController: Symbol("CreateBingoBoardButtonsController"),
	ModalCreateSingleBingoBoardController: Symbol("ModalCreateSingleBingoBoardController"),
	HOCSecretKeyController: Symbol("HOCSecretKeyController"),
	ModalPostSeasonController: Symbol("ModalPostSeasonController"),

	// Helpers
	FormValidator: Symbol("Validator for forms, should be initialized with passed scheme into"),
};
