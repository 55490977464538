import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {Nullable, NullOrEmpty} from "data/types/generics";
import {IAxiosApiErrorGeneral} from "data/types/api";

export interface ISquad {
	id: number;
	name: string;
	shortName: string;
	abbreviation: string;
	color: string;
	logo: Nullable<string>;
	logoWhiteBackground: Nullable<string>;
	blackWhiteLogo: Nullable<string>;
}

export type SquadLogos = "logo" | "logoWhiteBackground" | "blackWhiteLogo";

export interface ISquadsStore {
	get getIsLoading(): boolean;

	get list(): ISquad[];

	getSquadById(squadId: NullOrEmpty<number>): ISquad | undefined;

	fetchSquads(): Promise<void>;
}

@injectable()
export class SquadsStore implements ISquadsStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: ISquad[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	public getSquadById(squadId: NullOrEmpty<number>): ISquad | undefined {
		return this._list.find((squad) => squad.id === squadId);
	}

	@action
	async fetchSquads() {
		try {
			const {data} = await this._jsonProvider.squads();

			if (!Array.isArray(data)) {
				throw new Error("Invalid squads response");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			this._modalsStore.showAxiosError(e as IAxiosApiErrorGeneral);
		}
	}
}
