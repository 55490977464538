import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IBingoHighlightProp} from "data/types/bingo";
import {BingoHighlightHelper} from "data/utils/helpers/BingoHighlightHelper";
import {ContestStatus, PropCorrect} from "data/enums";
import {Empty} from "data/types/generics";
import {IContest, IGameBarEntity, IGameCard} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IGamePlayStore} from "data/stores/gameplay/gameplay.store";
import type {IGameBarStore} from "data/stores/gamebar/gamebar.store";

export interface IHOCCardsHighlightController extends ViewController {
	getAxisAccordingClass: (className: string) => string;

	goToNextContest: () => void;

	get isNextContestAvailable(): boolean;

	get classNames(): string[];

	get isCrossAllowedClass(): string;

	get lockedState(): boolean;
}

@injectable()
export class HOCCardsHighlightController implements IHOCCardsHighlightController {
	@observable protected _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.GamePlayStore) protected _gameplayStore: IGamePlayStore,
		@inject(Bindings.GameBarStore) protected _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isCrossAllowed = false;

	get isCrossAllowedClass(): string {
		return this._isCrossAllowed ? "cross-allowed" : "";
	}

	@observable protected _classNames: string[] = [];

	get classNames(): string[] {
		return this._classNames;
	}

	get lockedState(): boolean {
		return this._gameplayStore.isSelectedContestLocked && !this.gameCard;
	}

	get isNextContestAvailable(): boolean {
		return Boolean(this.nextOpenContest);
	}

	protected get gameCard(): Empty<IGameCard> {
		return this._gameplayStore.gameCard;
	}

	protected get selectedContest(): Empty<IContest> {
		return this._gameplayStore.selectedContest;
	}

	protected get nextOpenContest(): Empty<IContest> {
		const contestWithoutSelected = this._gameplayStore.contests.filter(
			(e) => e.id !== this.selectedContest?.id
		);
		return contestWithoutSelected.find((e) => e.status === ContestStatus.Open);
	}

	protected get gameBar(): Empty<IGameBarEntity[]> {
		return this._gameBarStore.gameBar;
	}

	dispose(): void {
		this._subscriptions$.forEach((subscription) => subscription());
	}

	init(param: void): void {
		this.getBingoBoardLines();
		const subscriptionGameCard = reaction(
			() => [this.gameCard, this.selectedContest, this.gameBar],
			() => this.getBingoBoardLines()
		);
		this._subscriptions$.push(subscriptionGameCard);
	}

	public getAxisAccordingClass = (className: string): string => {
		if (className.includes("horizontal")) {
			return "line-horizontal";
		}
		if (className.includes("vertical")) {
			return "line-vertical";
		}

		if (className.includes("cross")) {
			return "line-cross";
		}

		return "";
	};

	public goToNextContest = () => {
		if (!this.nextOpenContest) {
			return;
		}
		this._gameplayStore.setSelectedContestById(this.nextOpenContest.id);
	};

	protected getBingoBoardLines() {
		if (!this.gameCard?.items) {
			return;
		}
		const gameCardProps: IBingoHighlightProp[] = this.gameCard.items.map((e) => ({
			isCorrect: this.getIsPropCorrect(e.propId),
			position: e.position,
		}));

		this._classNames = BingoHighlightHelper.getBingoLinesClasses(gameCardProps);
		this._isCrossAllowed =
			this._classNames.filter((e) => e.includes("line-cross")).length === 2;
	}

	protected getIsPropCorrect(propId: number): boolean {
		const prop = this._gameplayStore.getPropById(propId);
		if (!prop) {
			return false;
		}
		return prop.result === PropCorrect.CORRECT;
	}
}
