import React from "react";
import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IFormResetPassword, ValidationScheme} from "data/types/entities";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import type {IUserStore} from "data/stores/user/user.store";
import type {Empty, Nullable} from "data/types/generics";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {PasswordValidator} from "data/utils/validators/PasswordValidator";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {NavigateFunction} from "react-router-dom";
import {ModalType} from "data/enums";

interface IParams {
	navigate: NavigateFunction;
	token: Nullable<string>;
}

export interface IResetPasswordController extends ViewController<IParams> {
	handleFormSubmit: (event: React.SyntheticEvent<IFormResetPassword>) => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	toggleConfirmPasswordVisibility: () => void;
	togglePasswordVisibility: () => void;
	close: () => void;

	get error(): Empty<string>;

	get formErrors(): IFormValidator["formErrors"];

	get isConfirmPasswordVisible(): boolean;

	get isLoading(): boolean;

	get isPasswordVisible(): boolean;
}

@injectable()
export class ResetPasswordController implements IResetPasswordController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
	};

	protected _navigate: Empty<NavigateFunction>;
	protected _token: Nullable<string> = null;

	constructor(
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get formErrors(): IFormValidator["formErrors"] {
		return this._formValidator.formErrors;
	}

	dispose(): void {
		return;
	}

	@action
	init(params: IParams): void {
		this._navigate = params.navigate;
		this._token = params.token;

		if (!params.token) {
			this._error = "Invalid token, please provide correct one";
			this._isLoading = true;
		}
	}

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		const name = event.target.name;
		if (!name) {
			return;
		}
		this._formValidator.clearError(name);
	};
	public handleFormSubmit = (event: React.SyntheticEvent<IFormResetPassword>) => {
		event.preventDefault();
		this.clearErrors();
		this._formValidator.validate(event.currentTarget);

		if (!this._formValidator.isValid || !this._token) {
			return;
		}

		const {password, confirmPassword} = event.currentTarget;
		if (password.value !== confirmPassword.value) {
			this._formValidator.setError("confirmPassword", "Passwords don't match");
			return;
		}

		this._isLoading = true;
		this._userStore
			.resetPassword({password: password.value, token: this._token})
			.then(this.onSuccess.bind(this))
			.catch(this.onFailure.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	public toggleConfirmPasswordVisibility = (): void => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action
	public togglePasswordVisibility = (): void => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	public close = () => {
		this._modalsStore.hideModal();
		this._navigate?.("/");
	};

	@action
	protected clearErrors(): void {
		this._formValidator.clearErrors();
		this._error = undefined;
	}

	@action
	protected onSuccess() {
		this.close();
		this._modalsStore.showModal(ModalType.LOGIN);
	}

	@action
	protected onFinally() {
		this._isLoading = false;
		this.close();
		this._modalsStore.showModal(ModalType.LOGIN);
	}

	@action
	protected onFailure(error: AxiosError<IAxiosApiError, unknown> | undefined) {
		if (!error) {
			this._error = "Error while reset password, please again later";
			return;
		}

		this._error = getErrorMessageFromAxiosResponse(error);
	}
}
