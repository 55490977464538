import {ThemeOptions} from "@mui/material/styles/createTheme";

export const switchTheme: ThemeOptions = {
	components: {
		MuiSwitch: {
			styleOverrides: {
				root: {
					"& .MuiSwitch-switchBase.Mui-checked": {
						color: "#121212",
					},
					"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
						backgroundColor: "#4D4D4D",
					},
				},
			},
		},
	},
};
