import {ViewController} from "data/types/structure";
import {ModalType} from "data/enums";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IModalMessage} from "data/types/modals";
import {Empty} from "data/types/generics";

interface IParams {
	modal: ModalType;
}
export interface IModalController extends ViewController<IParams> {
	close: () => void;

	isModalOpen: (modal: ModalType) => boolean;

	showTutorialModal: () => void;

	get modalData(): unknown;
}

@injectable()
export class ModalController implements IModalController {
	protected _modal: Empty<ModalType>;
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get modalData(): unknown {
		if (!this._modal) {
			return {};
		}
		return this._modalsStore.getModalContent(this._modal);
	}

	public close = (): void => {
		const data = this.modalData as IModalMessage;
		if (data?.callback && typeof data?.callback === "function") {
			data.callback();
		}
		this._modalsStore.hideModal(this._modal);
	};

	public isModalOpen = (modal: ModalType): boolean => {
		return this._modalsStore.isModalOpen(modal);
	};

	public showTutorialModal = (): void => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._modal = param.modal;
	}
}
